@import "../../mixins/global.scss";

.TotalPriceDiscountBadge {
  &__discountBadge {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 4px;
    background: #ffffff;
    border: 1px solid #c82900;
    border-radius: 64px;
    @include rxc-mobile-only {
      margin: 0px;
      margin-top: 11.5px;
      max-width: unset;
      width: 100%;
      padding: 2px 0px;
    }
    & p {
      font-family: "CABOTO";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #c82900;
      margin: 0px;
      & u {
        margin-left: 3px;
      }
    }
  }
}
