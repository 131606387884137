@import "../../mixins/global";

.InsuranceTooltip {
  &__icon {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-left: 8px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

    @include rxc-mobile-only {
      width: 16px;
      height: 16px;
      min-width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 10.6667V8' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 5.33337H8.00667' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  &__container {
    opacity: 0;
    position: fixed;
    max-width: 306px;
    transform: translateY(calc(-100% - 18px));
    background-color: white;
    padding: 16px 45px 24px 16px;
    box-shadow: 0px 0px 2.94545px rgb(40 41 61 / 4%),
      0px 5.89091px 11.7818px rgb(96 97 112 / 16%);
    border-radius: 6px;

    @include rxc-mobile-only {
      transform: translateY(calc(100% + 18px));
    }

    &.fadeIn {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-duration: 1s;
    }

    &.invisible {
      display: none;
    }

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.fadeOut {
      opacity: 0;
      animation-name: fadeOutOpacity;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-duration: 1s;
    }

    @keyframes fadeOutOpacity {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &__text {
      font: 12px "sofia-pro", Verdana, Arial, sans-serif;
      color: #222;
    }

    &__arrowDown {
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid white;
      transform: translateY(90%);
      -webkit-filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
      filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
    }

    &__arrowUp {
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 11px solid white;
      -webkit-filter: drop-shadow(0px -4px 2px rgba(96, 97, 112, 0.05));
      transform: translateY(-90%);
      filter: drop-shadow(0px -4px 2px rgba(96, 97, 112, 0.05));
    }

    &__close {
      width: 12px;
      height: 12px;
      min-width: 12px;
      position: absolute;
      top: 10px;
      right: 14px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9272 1.4265L12.5737 1.073L7.00048 6.64625L1.42723 1.073L1.07373 1.4265L6.64698 6.99975L1.07373 12.573L1.42723 12.9265L7.00048 7.35325L12.5737 12.9265L12.9272 12.573L7.35398 6.99975L12.9272 1.4265Z' fill='black' stroke='black' stroke-width='0.3'/%3E%3C/svg%3E%0A");
    }
  }
}
