.IncompatiblePrescriptionModal {
  &__container {
    display: block;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
    }
    &__popup {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40%;
      background-color: white;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      border: 0;
      box-shadow: 0 0 8px 0 rgb(0 0 0 / 7%);
      padding: 60px 20px 40px 20px;
      z-index: 1000;
    }
    &__title {
      font-size: 20px;
      margin-bottom: 25px;
    }
    &__subtitle {
      width: 75%;
    }
    &_button {
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      font-size: 13px;
      background-color: #1f1f24;
      margin-top: 25px;
      border: none;
      padding: 10px 15px;
      color: #ffffff;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
