@import "../../mixins/global";

.MainHeader {
  &__close {
    margin-right: 60px;
    margin-left: 52px;
    display: block;
    align-self: center;
    cursor: pointer;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-right: 52px;
    cursor: pointer;

    &.invisible {
      visibility: hidden;
    }
  }

  &__container {
    display: flex;
    width: 50%;
    height: 50px;
    right: 0;
    min-height: 50px;
    background-color: #f1f2f2;
    z-index: 1;
    justify-content: space-between;
    align-self: flex-end;
    align-items: center;

    @include rxc-mobile-only {
      display: none;
    }
  }

  &__containerMobile {
    display: none;

    @include rxc-mobile-only {
      display: flex;
      flex-direction: column;
      border-bottom: unset;
      width: 100%;
      z-index: 2;
      background-color: white;
      align-self: flex-end;
      align-items: center;
      right: 0;
      justify-content: start;

      &.insuranceEnabled {
        height: 81px;
        min-height: 81px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include rxc-mobile-only {
      box-shadow: 0 1.5px 5.9px 0 rgb(96 97 112 / 16%),
        0 0 2.9px 0 rgb(40 41 61 / 8%);
      height: 55px;

      &.insuranceEnabled {
        align-items: flex-start;
        height: 73px;
      }
    }
  }

  &__left {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @include rxc-mobile-only {
      &.insuranceEnabled {
        position: relative;
        top: 18px;
      }
    }

    &__back {
      display: flex;
      align-items: center;
      height: fit-content;
      font-size: 24px;
      font-weight: 500;
      margin-left: 40px;
      margin-right: 52px;
      cursor: pointer;

      @include rxc-mobile-only {
        margin-left: 24px;
        position: relative;
        margin-right: 15px;
      }

      &_invisible {
        visibility: hidden;
      }
    }
  }

  &__right {
    display: inherit;
    flex-flow: inherit;
    align-items: center;
    justify-content: space-between;

    @include rxc-mobile-only {
      margin-right: 24px;
      margin-left: 15px;

      &.insuranceEnabled {
        position: relative;
        top: 18px;
      }
    }

    &__insurance {
      text-align: right;
      box-sizing: border-box;
      display: flex;
      color: #326fa8;
      font: 15px $rxc-arnette--font-regular, Verdana, Arial, sans-serif;

      &__insuranceEnabled {
        font: 15px "sofia-pro", Verdana, Arial, sans-serif;
        color: #247534;
      }

      &__image {
        width: 14px;
        height: 17px;
        vertical-align: text-top;
        margin-right: 10px;
      }
    }
  }

  &__center {
    display: flex;
    flex-flow: column;
    flex: 1 1 100%;

    @include rxc-mobile-only {
      justify-content: center;
      align-items: center;

      &.insuranceEnabled {
        align-self: center;
      }
    }

    &.hideHeader {
      display: none;
    }
  }

  &__logocontainer {
    box-sizing: border-box;
    font: 15px $rxc-arnette--font-regular, Verdana, Arial, sans-serif;
  }

  &__logo {
    min-width: 172px;
    min-height: 21px;
    color: transparent;
    object-fit: contain;
    background-size: contain;
    background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo.png);
    background-image: image-set(url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo.png) 1x, url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo@3x.png) 3x);
    background-image: -webkit-image-set(url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo.png) 1x, url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/lc_logo@3x.png) 3x);
    background-repeat: no-repeat;
  }

  &__productname {
    text-transform: uppercase;
    margin-right: 150px;
    font: 15px $rxc-arnette--font-extra-light, Verdana, Arial, sans-serif;
  }

  &__insurance {
    @include rxc-mobile-only {
      font-family: "sofia-pro-bold" !important;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #557b92 !important;
      text-decoration: underline !important;
      margin-top: unset !important;
    }

    &__insuranceEnabled {
      font: 15px "sofia-pro", Verdana, Arial, sans-serif;
      color: #247534;
      display: flex;
      align-items: end;
    }
  }

  &__removeInsuranceLabel {
    font: 14px "sofia-pro", Verdana, Arial, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 3px;
    width: fit-content;
  }
}