@import "../../mixins/global";

.filterPills {
  &__fadecontainer {
    display: flex;
    align-items: center;
    ::-webkit-scrollbar {
      width: 0;
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    &__filtersContainer::-webkit-scrollbar {
      width: 0 !important;
      opacity: 0 !important;
      display: none !important;
    }

    &__filtersContainer {
      display: flex;
      margin-top: 14px;
      overflow-x: auto;
      scroll-behavior: smooth;
      vertical-align: baseline;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      @include rxc-mobile-only {
        margin-top: 0px;
      }
      &__fade {
        &__right {
          position: relative;
        }
        &__left {
          position: relative;
        }
        &__right::after {
          content: "";
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            white 100%
          );
          pointer-events: none !important;
          height: 50px !important;
          width: 80px !important;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99999999;
          bottom: 0;
          transform: translateY(-50%);
        }
        &__left::after {
          content: "";
          background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0),
            white 100%
          );
          pointer-events: none !important;
          height: 50px !important;
          width: 80px !important;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99999999;
          bottom: 0;
          transform: translate(125%, -50%);
        }
      }

      &__scrollButton {
        &__right {
          width: 8px;
          height: 11px;
          display: flex;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.86201 10.3651C1.12236 10.6101 1.54447 10.6101 1.80482 10.3651L6.47149 5.97289C6.73184 5.72786 6.73184 5.33058 6.47149 5.08555L1.80482 0.693388C1.54447 0.448353 1.12236 0.448353 0.86201 0.693388C0.601661 0.938423 0.601661 1.3357 0.86201 1.58074L5.05727 5.52922L0.86201 9.4777C0.601661 9.72274 0.601661 10.12 0.86201 10.3651Z' fill='%23222222'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-color: rgba(0, 0, 0, 0);
          border: none;
        }
        &__left {
          width: 8px;
          height: 11px;
          display: flex;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.86201 10.3651C1.12236 10.6101 1.54447 10.6101 1.80482 10.3651L6.47149 5.97289C6.73184 5.72786 6.73184 5.33058 6.47149 5.08555L1.80482 0.693388C1.54447 0.448353 1.12236 0.448353 0.86201 0.693388C0.601661 0.938423 0.601661 1.3357 0.86201 1.58074L5.05727 5.52922L0.86201 9.4777C0.601661 9.72274 0.601661 10.12 0.86201 10.3651Z' fill='%23222222'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-color: rgba(0, 0, 0, 0);
          border: none;
          transform: rotate(180deg);
        }
      }
      &__separatorvertical {
        &__left {
          width: 2px;
          background-color: $rxc-grey-separator;
          height: 44px;
          /*  margin-top: 8px; */
          margin-right: 5px;
          margin-left: 5px;
          /* @include rxc-mobile-only {
                        margin-top: 15px;
                    } */
        }
        &__right {
          width: 2px;
          background-color: $rxc-grey-separator;
          height: 44px;
          /*  margin-top: 8px; */
          margin-right: 5px;
          margin-left: 5px;
          /* @include rxc-mobile-only {
                        margin-top: 15px;
                    } */
        }
      }
    }
  }

  &__filterPill {
    background-color: $rxc-white-color-1;
    cursor: pointer;
    max-height: fit-content;
    font-family: $rxc-sun-default-sofia-font;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    color: #222222 !important;
    border: 1px solid #b8b8b8;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;

    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      font-family: $rxc-sun-default-sofia-font;
      color: $rxc-white-color-1 !important;
      background-color: $rxc-grey2-sun-default;
      border-radius: 100px;
      border: solid 1px $rxc-grey2-sun-default;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }

  &__container {
    flex-flow: column nowrap;
  }
}
