@import "../../mixins/global";

.NoPrescriptionFromMyAccountModal {
  font-family: $rxc-clearly-font;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .Modal {
    width: 50vw;
    margin: 0 auto;
    padding: 32px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;

    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin: 24px 0;
    }

    button {
      background: #000000;
      color: white;
      border-radius: 4px;
      padding: 9px 24px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }

    .closeIcon {
      width: fit-content;
      display: block;
      content: "";
      background: image-set(
        url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x
      );
      background: -webkit-image-set(
        url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x
      );
      background-repeat: no-repeat;
      color: transparent;
      min-width: 24px;
      height: 24px;
      background-size: 100%;
      align-self: center;
      cursor: pointer;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.5;
  }
}
