@import "../../mixins/global";

@keyframes show-title-animation {
  from {
    width: 0;
    height: 0;
  }
  50% {
    width: auto;
    height: 83px;
  }
  to {
    width: auto;
    height: 83px;
  }
}
@keyframes slide-top-animation {
  from {
    width: auto;
    height: 83px;
  }
  50% {
    width: auto;
    height: 83px;
  }
  75% {
    width: auto;
    height: 83px;
  }
  to {
    width: 0;
    height: 0;
  }
}

.YourSelection {
  @include rxc-mobile-only {
    display: none;
  }
  &__overlay {
    background-color: $rxc-grey2-sun-default;
    opacity: 50%;
    width: 50%;
    height: 100%;
    position: absolute;
    z-index: 99;
  }
  &__container {
    position: fixed;
    top: 24px;
    right: 52%;
    background-color: $rxc-white-color-1;
    border-radius: 8px;
    cursor: pointer;
    color: $rxc-grey2-sun-default;
    z-index: 100;

    &__displayNone {
      display: none;
    }

    &.isOpen {
      cursor: initial;
      width: 391px;
    }
    &__title_wrapper {
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 8px 16px;
      background-color: $rxc-white-color-1;
      &.isOpen {
        margin-bottom: 17.5px;
        width: 100%;
        box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
          0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
      }
      &.isClose {
        position: relative;
        box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
          0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
        align-items: center;
        width: 180px;
      }
      &__listIcon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background: image-set(
          url(#{$globalImageUrl}list.png) 1x,
          url(#{$globalImageUrl}list@2x.png) 2x,
          url(#{$globalImageUrl}list@3x.png) 3x
        );
        background: -webkit-image-set(
          url(#{$globalImageUrl}list.png) 1x,
          url(#{$globalImageUrl}list@2x.png) 2x,
          url(#{$globalImageUrl}list@3x.png) 3x
        );
        background-repeat: no-repeat;
        background-size: 100%;
        align-self: center;
      }
      &__title {
        text-transform: capitalize;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
      &__close {
        display: block;
        margin-left: auto;
        content: "";
        // background: image-set(
        //     url(#{$globalImageUrl}xclose.png) 1x,
        //     url(#{$globalImageUrl}xclose@2x.png) 2x,
        //     url(#{$globalImageUrl}xclose@3x.png) 3x
        // );
        // background: -webkit-image-set(
        //     url(#{$globalImageUrl}xclose.png) 1x,
        //     url(#{$globalImageUrl}xclose@2x.png) 2x,
        //     url(#{$globalImageUrl}xclose@3x.png) 3x
        // );
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.7992 1.13328L14.8659 0.199951L7.99922 7.06662L1.13255 0.199951L0.199219 1.13328L7.06589 7.99995L0.199219 14.8666L1.13255 15.8L7.99922 8.93328L14.8659 15.8L15.7992 14.8666L8.93255 7.99995L15.7992 1.13328Z' fill='black'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        color: transparent;
        width: 15px;
        height: 15px;
        background-size: 100%;
        align-self: center;
        cursor: pointer;
      }
    }
    &__content_wrapper {
      width: 100%;
      padding: 0 16px 12px 16px;
      font-family: "Mulish";
      font-style: normal;
      &__title {
        display: flex;
        margin-bottom: 5px;
        &__step {
          margin-right: 4px;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
        &__edit {
          color: #1c4da1;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          text-transform: capitalize;
          text-decoration: underline;
          cursor: pointer;
        }
        &__price_container {
          display: flex;
          margin-left: auto;
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          // .Price__container__inner__strikePrice {
          //     font-family: $rxc-default-sofia-font !important;
          //     font-size: $rxc-sun-default-12-fontSize !important;
          //     line-height: 18px;
          //     text-decoration: line-through;
          //     margin-left: 6px;
          // }
          // .Price__container__inner__price {
          //     font-family: $rxc-default-sofia-font !important;
          //     font-size: $rxc-sun-default-16-fontSize !important;
          //     line-height: 18px;
          // }
        }
      }
      &__selection {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
      &__separator {
        height: 1px;
        background-color: #b8b8b8;
        width: 100%;
        margin: 16px 0 8px 0;
        &:first-child {
          display: none;
        }
      }
    }
    &__current_title {
      display: flex;
      align-items: flex-end;
      width: 0;
      height: 0;
      overflow: hidden;
      padding: 12px 16px;
      background-color: $rxc-white-color-1;
      border-radius: 8px;
      color: $rxc-grey2-sun-default;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: -24px;
      z-index: 99;
      &.animated {
        animation: show-title-animation 1s 0.5s, slide-top-animation 2s 1.5s;
        box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
          0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
      }
    }
  }
}

.hideIncluded {
  display: none;
}
.includedInsurance {
  color: #247534 !important;
  font-family: $rxc-default-sofia-font;
  font-weight: bold;
}
.StepPrice__container__inner__strikePrice {
  font-size: 12px !important;
  line-height: 16px !important;
  margin-right: 4px !important;
}
.StepPrice__container__inner__price {
  font-size: 16px !important;
  line-height: 24px !important;
}
