@import "../../mixins/global.scss";
 
.ProceedWithNoPowerModal{
  &__overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container{
      position: relative;
      width: 764px;
      max-width: 764px;
      min-height: 156px;
      padding: 64px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      @include rxc-mobile-only{
        width: 90%;
        padding: 40px 16px;
      }
      &__close{
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg id='035-bold' clip-path='url(%23clip0_8201_48639)'%3e%3cpath id='Vector' d='M15.8002 1.13353L14.8669 0.200195L8.0002 7.06686L1.13353 0.200195L0.200195 1.13353L7.06686 8.0002L0.200195 14.8669L1.13353 15.8002L8.0002 8.93353L14.8669 15.8002L15.8002 14.8669L8.93353 8.0002L15.8002 1.13353Z' fill='black'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_8201_48639'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
      }

      &__title{
        font-family: $rxc-clearly-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 28px; 
        text-transform: uppercase;
        @include rxc-mobile-only{
          text-align: center;
        }
      }

      &__subtitle{
        margin: 16px 0px;
        font-family: $rxc-clearly-font;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        max-width: 50%;
        text-align: center;
        @include rxc-mobile-only{
          max-width: 100%;
        }
      }

      &__buttonsGroup{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include rxc-mobile-only{
          flex-direction: column;
        }
        &__cancel{
          padding: 12px 24px;
          background-color: #fff;
          color: #000;
          border: 1px solid #000;
          border-radius: 4px;
          font-family: $rxc-clearly-font;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          margin-right: 24px;
          cursor: pointer;
          @include rxc-mobile-only{
            margin-right: 0px;
            margin-bottom: 24px;
            width: 100%;
          }
        }
        &__continue{
          padding: 12px 24px;
          background-color: #000;
          color: #fff;
          border-radius: 4px;
          font-family: $rxc-clearly-font;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          @include rxc-mobile-only {
            width: 100%;
          }
        }
      }
    }
  }
}