@import "../../mixins/global";

.PrescriptionModalNoAdd {
  &__overlay {
    position: absolute;
    background-color: rgba(51, 51, 51, 0.6);
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: fit-content;
    background-color: white;
    z-index: 101;
    display: block;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include rxc-desktop-only {
      width: 630px;
      height: 241px;
    }

    @include rxc-mobile-only {
      width: 90%;
    }
    &__close {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 18px;
      height: 18px;
      background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.7746 1.275L16.7246 0.224998L8.99961 7.95L1.27461 0.224998L0.224609 1.275L7.94961 9L0.224609 16.725L1.27461 17.775L8.99961 10.05L16.7246 17.775L17.7746 16.725L10.0496 9L17.7746 1.275Z' fill='black'/%3e%3c/svg%3e");
      cursor: pointer;
    }

    &__title {
      font-family: $rxc-opsm-barlow-bold-font;
      font-size: 18px;
      font-weight: 400;
      color: #1f1f24;
      text-align: start;
      padding: 5px 0 15px 0;
      text-transform: uppercase;

      @include rxc-mobile-only {
        font-size: 18px;
        text-align: center;
      }
    }

    &__text {
      text-align: center;
      font: 18px $rxc-opsm-barlow-font;
      line-height: 150%;
    }

    &__buttons {
      display: flex;

      @include rxc-mobile-only {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
      }
    }

    &__continueButton {
      margin-bottom: 15px;
      height: 48px;
      border-radius: 24px !important;
      background-color: #ffffff !important;
      color: #2d2a26 !important;
      border: 2px solid #2d2a26 !important;
      text-transform: uppercase;
      font-family: $rxc-opsm-barlow-bold-font !important;
      font-size: 16px !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        margin-top: 40px;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-right: 20px;
        font-size: 14px !important;
        width: fit-content;
        margin-top: 20px;
        padding: 0px 30px 0px 32px;
      }
      // &:hover {
      //   background-color: $rxc-blue-color-2 !important;
      //   border: 2px solid $rxc-blue-color-2 !important;
      //   box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
      //     0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
      // }
    }

    &__cancelButton {
      margin-top: 20px;
      margin-bottom: 15px;
      height: 48px;
      border-radius: 24px !important;
      background-color: #2d2a26 !important;
      color: #ffffff !important;
      border: 2px solid #2d2a26 !important;
      text-transform: uppercase;
      font-size: 16px !important;
      font-family: $rxc-opsm-barlow-bold-font !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-left: 20px;
        font-size: 14px !important;
        width: fit-content;
        padding: 0px 30px 0px 32px;
      }
      // &:hover {
      //   box-shadow: 0 0 0 1px $rxc-blue-skin-default;
      //   background-color: rgba(85, 123, 146, 0.05) !important;
      // }
    }
  }
}
