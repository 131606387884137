@import "../../mixins/global";

.SelectFromMyAccount {
  font-family: $rxc-clearly-font;
  font-size: 14px;

  &__subtitle {
    margin: 0;
    margin-bottom: 24px;
    white-space: pre-line;
    display: inline;

    @include rxc-mobile-only {
      white-space: normal;
      margin-bottom: 20px;
    }
  }

  &__add {
    color: #1c4da1;
    display: inline;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }

  .PrescriptionFromMyAccountCard {
    margin-top: 32px;
  }
}
