@import "../../mixins/global";

.StepTitle {
  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: absolute;
      background-color: $rxc-grey2-sun-default;
      padding: 8px 20px 8px 20px;
      right: auto;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-sun-default-sofia-font;
      font-weight: 700;
      margin-bottom: 320px;
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 35px 10px 35px;
    border-radius: 100px;
    background-color: $rxc-grey2-sun-default;
    border: none;
    position: absolute;
    z-index: 1;
    right: 72px;
    margin-bottom: 23px;
    font-family: $rxc-sun-default-sofia-font;
    font-size: $rxc-sun-default-14-fontSize;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 92px;
      left: 0;
    }
  }

  &__filtersContainer {
    display: flex;
    margin-top: 8px;
    margin-bottom: 18px;
    overflow-x: auto;
    @include rxc-mobile-only {
      margin-bottom: 18px;
      margin-top: 18px;
    }
  }

  &__filterPill {
    background-color: $rxc-white-color-1;
    cursor: pointer;
    font-family: $rxc-sun-default-sofia-font;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    border: solid 1px $rxc-grey-separator;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;

    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      font-family: $rxc-sun-default-sofia-font;
      color: $rxc-white-color-1;
      background-color: $rxc-grey2-sun-default;
      border-radius: 100px;
      border: solid 1px $rxc-grey2-sun-default;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }

  &__slider {
    &__container {
      width: fit-content;
      margin-bottom: 16px;
      border-radius: 100px;
      font-family: $rxc-sun-default-sofia-font;
      border: solid 1px $rxc-blue-skin-default;
      @include rxc-mobile-only {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 16px auto 0px auto;
      }
      &__btn {
        width: fit-content;
        background-color: $rxc-white-color-1;
        border-radius: 100px;
        border: 1px solid $rxc-white-color-1;
        font-size: $rxc-sun-default-14-fontSize;
        font-family: $rxc-sun-default-sofia-font;
        color: $rxc-blue-skin-default;
        padding: 8px 16px 8px 16px;
        transition: 0.3s;
        @include rxc-mobile-only {
          box-sizing: border-box;
          height: 32px;
          font-size: $rxc-sun-default-12-fontSize;
        }

        &.selected {
          color: $rxc-white-color-1;
          background-color: $rxc-blue-skin-default;
          border-radius: 100px;
          font-family: $rxc-sun-default-sofia-font;
          font-size: $rxc-sun-default-14-fontSize;
          border: 1px solid $rxc-blue-skin-default;
          padding: 8px 16px 8px 16px;
          @include rxc-mobile-only {
            width: fit-content;
            font-size: $rxc-sun-default-12-fontSize;
          }
        }
      }
    }
  }

  &__container {
    flex-flow: column nowrap;
    font-family: "Mulish";
    font-style: normal;
    @include rxc-desktop-only {
      padding: 10px 0px 24px;
    }

    @include rxc-mobile-only {
      padding: 16px 0px 17px 0;
    }

    &__title {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      @include rxc-mobile-only {
        display: none;
      }
    }

    &__titleMobile {
      display: none;

      @include rxc-mobile-only {
        display: block;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    &__subtitle {
      box-sizing: border-box;
      font-family: $rxc-sun-default-sofia-font;
      font-size: $rxc-sun-default-14-fontSize;
      text-align: left;
      color: $rxc-grey-sun-default;
      margin-top: 8px;
      margin-bottom: 16px;
      word-wrap: break-word;
    }

    &__pillsSubtitle {
      box-sizing: border-box;
      font-family: $rxc-sun-default-sofia-font;
      font-size: $rxc-sun-default-14-fontSize;
      text-align: left;
      color: $rxc-grey-sun-default;
      margin-top: 8px;
      word-wrap: break-word;
    }
  }
}
