.StepCardCheck {
  &__substep {
    margin-top: 50px;
    &_header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &_title {
      font: 18px "sofia-pro-light", Verdana, Arial, sans-serif;
      //TODO only mobile
      margin-left: 16px;
    }
    & .border-button {
      display: block;
    }
  }
  &__container {
    display: block;
    box-sizing: border-box;
  }
  &_buttonnext {
    width: 140px;
    height: 30px;
    align-self: flex-end;
    font: 14px "sofia-pro-light", Verdana, Arial, sans-serif;
    background-color: #326fa8;
    color: white;
    border-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    font: 12px "sofia-pro", Verdana, Arial, sans-serif;
    border-radius: 12px;
    cursor: pointer;

    &_container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &_button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 8px 0 #d3d3d3;
    padding: 0 14px;
    margin-bottom: 20px;
    opacity: 1;
    cursor: pointer;
    &:hover {
      outline: inherit;
      border: 2px solid #326fa8;
      background-color: white;

      & .StepCardCheck_button_title {
        text-decoration: underline;
      }
    }
    &:focus {
      outline: inherit;
      box-shadow: 0px 0px 0px 3.5px #326fa8;
    }

    //TODO only desktop
    position: relative;

    &_selected {
      border: 2px solid #326fa8;
      background-color: white;
      & .StepCardCheck_button_title {
        font: 18px "sofia-pro-light", Verdana, Arial, sans-serif;
      }
      & .StepCardCheck_button_icon {
        width: 44px;
        height: 45px;
        background-image: url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked.png);
        background-image: image-set(url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked.png) 1x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked@3x.png) 3x);
        background-image: -webkit-image-set(url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked.png) 1x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add-clicked@3x.png) 3x);
      }
    }

    &_content {
      display: flex;
      flex-flow: row nowrap;
      width: 80%;
      align-items: center;
    }
    &_innercontent {
      display: flex;
      flex-flow: column nowrap;
      min-width: 100%;
    }
    &_image {
      max-width: 65px;
      max-height: 45px;
      margin-right: 16px;
    }
    &_title {
      font: 18px "sofia-pro-light", Verdana, Arial, sans-serif;
      width: 80%;
      text-align: left;
      color: #333333;
      font-weight: 500;
    }

    &_icon {
      width: 45px;
      height: 45px;
      margin-left: 16px;
      background-image: url(https://assets.lenscrafters.com/extra/image/rxc/images/add.png);
      background-image: image-set(url(https://assets.lenscrafters.com/extra/image/rxc/images/add.png) 1x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add@3x.png) 3x);
      background-image: -webkit-image-set(url(https://assets.lenscrafters.com/extra/image/rxc/images/add.png) 1x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add@2x.png) 2x, url(https://assets.lenscrafters.com/extra/image/rxc/images/add@3x.png) 3x);
      background-position: center;
    }
  }
}
