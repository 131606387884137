@import "../../mixins/global";
.WarningBanner {
  &__container {
    font-family: "Mulish";
    font-style: normal;
    display: flex;
    background-color: #ffefcf;
    margin-top: 25px;
    margin-bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    padding: 0px 15px 0px 15px;
    min-width: 100%;

    &__tooltip {
      font-family: $rxc-default-sofia-font;
      font-size: $rxc-sun-default-12-fontSize;
      z-index: 1;
      width: fit-content;
      justify-content: center;
      position: absolute;
      margin-top: 3.8%;
      left: 20px;
      max-width: 289px;
      padding: 23px 26px 23px 31px;
      background-color: $rxc-white-color-1;
      -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      border-radius: 4px;

      @include rxc-mobile-only {
        margin-top: 10px;
      }
    }
    &__icon {
      position: absolute !important;
      top: 13px;
      height: 16px;
      width: 16px;
    }
    &__text {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      padding: 10px 30px 10px 30px;
      display: block !important;
      white-space: break-spaces;
      @include rxc-desktop-only {
        max-width: 100%;
      }
      &__withAfter {
        font-size: 12px;
        padding: 10px 30px 10px 30px;
        display: block !important;
        line-height: 1.33;
        letter-spacing: 0.16px;
        white-space: break-spaces;
        color: #557480;
        pointer-events: none;
        &::after {
          content: attr(data-content);
          color: #244c5a;
          text-decoration: underline;
          pointer-events: all;
        }
      }
    }
  }
}
