@import "../../mixins/global.scss";

.OpenButton {
  &__container {
    //height: 40px;
    margin-top: 80px;
    padding: 4px 16px;
    border-radius: 100px;
    border-radius: 100px;
    border: 1px solid #DDE1E3;
    background: radial-gradient(109.36% 139.15% at 50.22% 0%, #E4EBEF 0%, rgba(231, 235, 237, 0.28) 100%);
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    @include rxc-mobile-only {
      margin-top: 0;
      margin: 0 16px 16px;
    }

    &:hover {
      cursor: pointer;
    }

    &__icon {
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none"><path d="M15 2.00004H12.138L10.569 0.431041C10.5067 0.368374 10.4217 0.333374 10.3333 0.333374H5.66667C5.57833 0.333374 5.49333 0.368374 5.431 0.431041L3.862 2.00004H1C0.448667 2.00004 0 2.44871 0 3.00004V10.3334C0 10.8847 0.448667 11.3334 1 11.3334H15C15.5513 11.3334 16 10.8847 16 10.3334V3.00004C16 2.44871 15.5513 2.00004 15 2.00004ZM15.3333 10.3334C15.3333 10.5174 15.1837 10.6667 15 10.6667H1C0.816333 10.6667 0.666667 10.5174 0.666667 10.3334V3.00004C0.666667 2.81604 0.816333 2.66671 1 2.66671H4C4.08533 2.66671 4.17067 2.63404 4.23567 2.56904L5.80467 1.00004H10.1953L11.7643 2.56904C11.8293 2.63404 11.9147 2.66671 12 2.66671H15C15.1837 2.66671 15.3333 2.81604 15.3333 3.00004V10.3334Z" fill="%23192C50"/><path d="M8 3.33337C6.346 3.33337 5 4.67904 5 6.33337C5 7.98771 6.346 9.33337 8 9.33337C9.654 9.33337 11 7.98771 11 6.33337C11 4.67904 9.654 3.33337 8 3.33337ZM8 8.66671C6.71333 8.66671 5.66667 7.61971 5.66667 6.33337C5.66667 5.04704 6.71333 4.00004 8 4.00004C9.28667 4.00004 10.3333 5.04704 10.3333 6.33337C10.3333 7.61971 9.28667 8.66671 8 8.66671Z" fill="%23192C50"/></svg>')
    }

    &__label {
      color: var(--Blue-LC, #192C50);
      text-align: center;
      font-family: $rxc-sun-default-sofia-font;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 133.333% */
      letter-spacing: 0.16px;
      margin: 0;
    }
  }
}