.ErrorBundary {
  &__container {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
