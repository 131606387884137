@import "../../mixins/global";

.savings {
  font-size: 14px;
  font-family: $rxc-default-sofia-font;
  font-weight: 700;
  color: #489F2D;
  margin: 0;
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 4px;

  .StepPrice__container__inner__price {
    font-size: 14px;
    font-family: $rxc-default-sofia-font;
    font-weight: 700;
    color: #489F2D !important;

    @include rxc-mobile-only {
      font-size: 12px;
      margin-left: 0;
    }
  }

  @include rxc-mobile-only {
    font-size: 12px;
  }
}