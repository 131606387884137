@import "../../mixins/global";

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.BottomBar {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 72px;
  background-color: #f1f2f2;
  z-index: 1;

  &__hide {
    display: none;
  }

  &__legalBanner {
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 102px;
    background-color: #f1f2f2;
    z-index: 1;

    @include rxc-mobile-only {
      display: none;
    }
  }

  .StepPrice__container__inner__GVPLabel {
    display: none;
  }

  @include rxc-mobile-only {
    display: none;
  }

  &::before {
    content: "";
    margin-left: 40px;
    margin-right: 40px;
    border-top: 1px solid #b8b8b8;
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 0px;
    justify-content: space-between !important;
    margin: 0 40px !important;

    &__containerLastPage {
      justify-content: space-between;
      margin-left: 40px;
      margin-right: 0px !important;

      .savings {
        display: none;
      }
    }

    &__noInsurance {
      justify-content: flex-end !important;

      &__lastStep {
        justify-content: space-between;
        position: relative;

        &__visionCareContainer {
          display: flex;
          align-items: center;

          &__visionCareLabel {
            display: inline-block;
            font-family: "Mulish";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #43358d;
            // & span {
            //   font-weight: bold;
            //   @include rxc-mobile-only {
            //     margin-left: 5px;
            //   }
            // }
          }

          &__visionCareIcon {
            position: relative;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 4px;
            background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%2343358D'/%3e%3cpath d='M8.66634 6.66699H7.33301V12.0003H8.66634V6.66699Z' fill='%2343358D'/%3e%3cpath d='M8.66634 4H7.33301V5.33333H8.66634V4Z' fill='%2343358D'/%3e%3c/svg%3e");

            &:hover {
              & .VisionCareTooltip {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }

            & .VisionCareTooltip {
              display: none;
              position: absolute;
              padding: 12px 16px;
              font-family: "Mulish";
              font-style: normal;
              top: -140px;
              left: -140px;
              width: 320px;
              height: 130px;
              background: #3d3c3c;
              border-radius: 2px;
              color: white;

              &::after {
                content: "";
                position: absolute;
                bottom: -8px;
                left: 44%;
                height: 16px;
                width: 16px;
                background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%233D3C3C'/%3e%3c/svg%3e");
              }

              &__title {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                margin: 0px 0px 8px 0px;
              }

              &__description {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin: 0px;
              }
            }
          }
        }
      }
    }

    &__left {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      &__wrapper {
        display: flex;
        flex-direction: column;
      }

      &__insuranceWrapper {
        display: flex;
      }

      &__insuranceIcon {
        height: 24px;
        width: 24px;
        min-width: 24px;
        margin-left: 8px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &__removeInsuranceLabel {
        font: 14px "sofia-pro", Verdana, Arial, sans-serif;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 3px;
        width: fit-content;
      }

      &__insurance {
        text-align: right;
        box-sizing: border-box;
        display: flex;
        color: #557b92 !important;
        font: 16px "sofia-pro-bold", Verdana, Arial, sans-serif;
        border: none;
        background: none;
        text-decoration: underline;
        cursor: pointer;
      }

      &__insuranceEnabled {
        font: 16px "sofia-pro", Verdana, Arial, sans-serif;
        color: #247534;
      }

      &__addInsuranceButton {
        height: 40px;
        border-radius: 100px;
        background-color: white;
        color: #222222;
        text-transform: uppercase;
        font-family: "sofia-pro-light";
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        padding: 0 32px;
        align-self: center;
        border: solid 1px #222222;
        letter-spacing: 0.5px;
        cursor: pointer;
        user-select: none;

        @include rxc-desktop-only {
          width: fit-content;
        }

        @include rxc-mobile-only {
          width: 100%;
          margin: 24px 0;
        }

        &:hover {
          box-shadow: 0 0 0 1px $rxc-grey2-sun-default;
          background-color: rgba(34, 34, 34, 0.05) !important;
        }
      }
    }

    &__right {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-right: 0px;
      margin-left: 20px;
      width: fit-content;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;

      &__noInsurance {
        margin-right: 30px;
      }

      &__legalBanner {
        margin: 4px 0 4px 6px;
        font-family: "Mulish";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: right;
        color: #666;
      }

      &__addToBagButton {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: fit-content;
        border-radius: 4px;
        gap: 8px;
        background-color: #005D30;
        color: #ffffff;
        text-transform: uppercase;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding: 8px 24px;
        align-self: center;
        border: none;
        letter-spacing: 0.5px;
        min-width: 163px;
        cursor: pointer;

        @include rxc-mobile-only {
          width: 100%;
        }

        @include rxc-desktop-only {
          &:hover {
            background-color: #67A78C;
            // box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
            //   0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
          }
        }

        &.disabled {
          background: #B8B8B8;
        }

        &.loading {
          &:hover {
            background-color: #222222;
            box-shadow: initial;
            cursor: initial;
          }
        }

        & img {
          animation: loading-animation 2s infinite linear;
        }
      }
    }

    &__notSuitable {
      width: 94%;
      height: 51px;
      align-self: flex-start;
      padding: 17.5px 40px;
      background-color: #ffefcf;
      margin-top: 24px;

      & p {
        position: relative;
        margin: 0px;
        padding: 0px;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        &::before {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          left: -25px;
          background-position: center center;
          background-size: contain;
          background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.66668 5.33255H7.33335V10.6659H8.66668V5.33255Z' fill='%23543C32'/%3e%3cpath d='M8.66668 11.9992H7.33335V13.3326H8.66668V11.9992Z' fill='%23543C32'/%3e%3cpath d='M15.7633 12.9152L9.78235 1.16689C9.41135 0.438552 8.70568 0.0742188 8.00001 0.0742188C7.29435 0.0742188 6.58868 0.438552 6.21768 1.16689L0.23668 12.9152C-0.481986 14.3266 0.543347 15.9992 2.12735 15.9992H13.873C15.4567 15.9992 16.482 14.3266 15.7633 12.9152ZM14.5447 14.2896C14.4753 14.4029 14.271 14.6659 13.8727 14.6659H2.12735C1.72901 14.6659 1.52468 14.4029 1.45535 14.2896C1.38601 14.1766 1.24435 13.8749 1.42501 13.5202L7.40601 1.77189C7.57335 1.44322 7.87701 1.40755 8.00001 1.40755C8.12301 1.40755 8.42668 1.44289 8.59401 1.77189L14.575 13.5202C14.7557 13.8752 14.614 14.1766 14.5447 14.2896Z' fill='%23543C32'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

.flexEnd {
  justify-content: flex-end !important;
}