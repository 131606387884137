@import "../../mixins/global";

.GrayOutBanner {
  &__container {
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 10px;
    background-color: #e5ebfe;
    align-items: flex-start;
    margin-top: 24px;

    @include rxc-mobile-only {
      margin-top: 16px;
    }

    &__icon {
      width: 14px;
      min-width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6.5' stroke='%23575D9B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 10.5H6.5L6.5 5.5H7.5L7.5 10.5ZM7.5 4.5H6.5V3.5H7.5V4.5Z' fill='%23575D9B'/%3E%3C/svg%3E%0A");
    }

    &__message {
      margin-left: 12px;
      color: #575d9b;
      font: 12px $rxc-glasses-DMSans-font;
    }
  }
}
