@import "../../mixins/global";

.Loader {
  &__container {
    position: relative;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  &__icon {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    object-fit: contain;
    animation: lds-dual-ring 1.2s linear infinite;
    background-image: url(#{$globalImageUrl}global_loader_icon_green.png);
    background-image: image-set(
      url(#{$globalImageUrl}global_loader_icon_green.png) 1x,
      url(#{$globalImageUrl}global_loader_icon_green@2x.png) 2x,
      url(#{$globalImageUrl}global_loader_icon_green@3x.png) 3x
    );
    background-image: -webkit-image-set(
      url(#{$globalImageUrl}global_loader_icon_green.png) 1x,
      url(#{$globalImageUrl}global_loader_icon_green@2x.png) 2x,
      url(#{$globalImageUrl}global_loader_icon_green@3x.png) 3x
    );

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__iconNonGreen {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    object-fit: contain;
    animation: lds-dual-ring 1.2s linear infinite;
    background-size: contain;
    background-image: url(#{$globalImageUrl}global_loader_icon_green.png);
    background-image: image-set(
      url(#{$globalImageUrl}global_loader_icon_green.png) 1x,
      url(#{$globalImageUrl}global_loader_icon_green@2x.png) 2x,
      url(#{$globalImageUrl}global_loader_icon_green@3x.png) 3x
    );
    background-image: -webkit-image-set(
      url(#{$globalImageUrl}global_loader_icon_green.png) 1x,
      url(#{$globalImageUrl}global_loader_icon_green@2x.png) 2x,
      url(#{$globalImageUrl}global_loader_icon_green@3x.png) 3x
    );
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
