@import "../../mixins/global";

.CallMyDoctorButton {
  width: fit-content;
  height: 40px;
  margin-top: 3px;
  padding: 0 35px;
  border-radius: 100px;
  background-color: $rxc-blue-skin-default;
  border: none;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-family: $rxc-sun-default-sofia-font;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 35px;
  letter-spacing: 0.5px;

  @include rxc-mobile-only {
    margin: 5px 0px 0px 0px;
    padding: 8px 19px;
    font-size: 16px;
  }
  &:hover {
    background-color: $rxc-blue-color-2 !important;
    box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
      0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
  }
}

.optionsSlider__slider__container {
  border: 1px solid #d8d8d8 !important;

  .optionsSlider__slider__container__btn.clicked {
    background-color: #222222;
    border: 3px solid #222222;
    color: white;
  }

  .optionsSlider__slider__container__btn {
    color: #222222;
  }
}

.CallMyDoctorPrescriptionForm {
  &__subtitle {
    font-size: 14px;
    font-family: $rxc-sun-default-sofia-font;
    margin-bottom: 32px;
    letter-spacing: 0.5px;
  }

  &__submitContainer {
    display: flex;
    position: relative;
    height: 100%;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;
    padding-bottom: 20px;
  }

  &__submitButton {
    width: fit-content;
    height: 40px;
    border: unset;
    padding: 10px 35px 10px 35px;
    float: right;
    margin-right: 15px;
    border-radius: 100px;
    background-color: $rxc-blue-skin-default;
    position: relative;
    font-size: 14px;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 500;
    text-align: center;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
  }
}
table {
  border: 0px solid black;
}

.SearchBy {
  &__container {
    .optionsSlider__slider__container {
      @include rxc-mobile-only {
        margin-left: 0;
        margin-bottom: 28px;
      }
    }

    .PhoneInputCountry {
      display: none !important;
    }

    .PhoneInput {
      width: 200px;

      input:focus {
        outline: none !important;
        border: 2px solid #222222;
      }
    }

    .PhoneInputInput {
      /* The phone number input stretches to fill all empty space */
      flex: 1;
      /* The phone number input should shrink
           to make room for the extension input */
      height: 48px;
      width: 220px;
      font-family: $rxc-sun-default-sofia-font;
      padding-left: 16px;
      border: 1px #d8d8d8 solid;
      border-radius: 4px !important;

      &__label {
        position: absolute;
        left: 0%;
        top: 0;
        padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
        margin: calc(0.5rem * 0.75 + 3px) calc(2rem * 0.5);
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background: white;
        line-height: 1.2;
        pointer-events: none;
        font-weight: 400;
        color: #767676;
      }
      &__labelFloat {
        position: absolute;
        left: 0%;
        top: 0;
        padding: calc(0.7rem * 0.75) calc(0.5rem * 0.5);
        margin: calc(0.5rem * 0.75 + 3px) calc(1.5rem * 0.5);
        white-space: nowrap;
        transform: translate(0.25rem, -65%) scale(0.8);
        transform-origin: 0 0;
        background: white;
        transition: transform 120ms ease-in;
        line-height: 1.2;
        pointer-events: none;
      }
    }

    .input {
      position: relative;
      font-family: $rxc-sun-default-sofia-font;
      width: 100%;
      max-width: unset;
      &.select {
        cursor: pointer;
        -webkit-appearance: none;
        & select {
          cursor: pointer;
          -moz-appearance: none;
          -webkit-appearance: none;
          -ms-appearance: none;
        }
        &::after {
          content: "";
          width: 12px;
          height: 10px;
          position: absolute;
          top: 22px;
          right: 16px;
          background-size: contain;
          background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/arrow-down-black-ico.svg);
          background-repeat: no-repeat;
          cursor: pointer;
          pointer-events: none;
        }
      }
      &__container {
        display: flex;
        column-gap: 16px;

        @include rxc-mobile-only {
          flex-wrap: wrap;
        }
      }

      @include rxc-mobile-only {
        margin-bottom: 28px;
      }

      @media only screen and (min-width: 732px) {
        max-width: 340px;
      }

      &.stateInputMobileMaxWidth {
        @include rxc-mobile-only {
          max-width: 200px;
          margin-bottom: 0;
        }
      }

      &.valid {
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='%23247534'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.6678 9.2318C16.0595 9.58537 16.1124 10.2159 15.786 10.6402L11.9399 15.6402C11.7739 15.856 11.5317 15.9862 11.2726 15.999C11.0135 16.0117 10.7614 15.9058 10.578 15.7071L8.27036 13.2071C7.90988 12.8166 7.90988 12.1834 8.27036 11.7929C8.63085 11.4024 9.2153 11.4024 9.57579 11.7929L11.1687 13.5186L14.3678 9.35984C14.6941 8.93556 15.2762 8.87824 15.6678 9.2318Z' fill='%23247534'/%3E%3C/svg%3E%0A");
        }
      }

      &__label {
        position: absolute;
        left: 0%;
        top: 0;
        padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
        margin: calc(0.5rem * 0.75 + 3px) calc(1.5rem * 0.5);
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background: transparent;
        transition: transform 120ms ease-in;
        line-height: 1.2;
        font-weight: 400;
        color: #767676;
      }
      &__field {
        width: 100%;
        box-sizing: border-box;
        display: block;
        height: 48px;
        border: 1px #d8d8d8 solid;
        border-radius: 4px !important;
        padding-left: 15px;
        background: transparent;
        &:focus,
        &:not(:placeholder-shown) {
          & + .input__label {
            transform: translate(0.25rem, -65%) scale(0.8);
            color: #222222;
          }
        }

        &.error {
          border: 1px solid #e32118;
        }

        &.green {
          border: 1px solid #247534;
          padding-right: 50px;
        }

        &.placeholder {
          font: 16px "sofia-pro";
          line-height: 1.2;
          font-weight: 400;
          color: #767676;
          padding: 15px;
          option {
            font: inherit !important;
          }
        }

        @include rxc-mobile-only {
          font-size: 16px;
        }
      }
    }
  }

  &_numberContainer {
    position: relative;
    width: 200px;
    margin-bottom: 20px;

    @include rxc-mobile-only {
      position: relative;
      width: fit-content;
      margin-right: 15px;

      .PhoneInput {
        width: fit-content;
        display: flex;
      }
    }

    .PhoneInputInput {
      @include rxc-mobile-only {
        font-size: 16px;
      }
    }
  }
  &_numberContainerError {
    position: relative;
    width: 200px;

    @include rxc-mobile-only {
      position: relative;
      width: fit-content;
      margin-right: 15px;
      margin-bottom: 20px;

      .PhoneInput {
        width: fit-content;
        display: flex;
      }
    }

    .PhoneInputInput {
      border: 1px solid #e32118;

      @include rxc-mobile-only {
        font-size: 16px;
      }
    }
  }
  &_numberContainerGreen {
    position: relative;
    width: 200px;
    margin-bottom: 20px;

    @include rxc-mobile-only {
      position: relative;
      width: fit-content;
      margin-right: 15px;

      .PhoneInput {
        width: fit-content;
        display: flex;
      }
    }

    .PhoneInputInput {
      border: 1px solid #247534;

      @include rxc-mobile-only {
        font-size: 16px;
      }
    }

    .PhoneInput {
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: -2px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='%23247534'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.6678 9.2318C16.0595 9.58537 16.1124 10.2159 15.786 10.6402L11.9399 15.6402C11.7739 15.856 11.5317 15.9862 11.2726 15.999C11.0135 16.0117 10.7614 15.9058 10.578 15.7071L8.27036 13.2071C7.90988 12.8166 7.90988 12.1834 8.27036 11.7929C8.63085 11.4024 9.2153 11.4024 9.57579 11.7929L11.1687 13.5186L14.3678 9.35984C14.6941 8.93556 15.2762 8.87824 15.6678 9.2318Z' fill='%23247534'/%3E%3C/svg%3E%0A");

        @include rxc-mobile-only {
          right: 20px;
          top: 14px;
          transform: unset;
        }
      }
    }

    input {
      padding-right: 50px;
    }
  }
  &__searchContainer {
    display: flex;
    font-family: $rxc-sun-default-sofia-font;
    @include rxc-mobile-only {
      /* width: calc(100vw - 40px); */
      flex-wrap: wrap;
    }
  }
  &__clinicContainer {
    & input {
      margin-left: 15px;
      height: 35px;
    }
    &__button {
      width: 200px;
      height: 40px;
      padding: 0 35px;
      border-radius: 100px;

      background-color: $rxc-blue-skin-default;
      position: relative;
      z-index: 1;
      font-size: 16px;
      font-family: $rxc-sun-default-sofia-font;
      font-weight: 500;
      text-align: center;
      color: white;
      border: 1px solid $rxc-blue-skin-default;
      text-transform: uppercase;
      cursor: pointer;
      left: 50%;
      transform: translateX(-60%);
      margin-top: 15px;
      letter-spacing: 0.5px;

      @include rxc-mobile-only {
        left: 0;
        transform: unset;
        margin-top: 20px;
      }
      &:hover {
        background-color: $rxc-blue-color-2 !important;
        box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
          0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
      }
    }
  }

  &__title {
    font-size: 14px;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 600;
    margin-bottom: 16px;

    @include rxc-mobile-only {
      margin-bottom: 0;
    }
  }

  &__phoneNumber {
    height: 48px;
    width: 340px;
    margin-right: 23px;
    padding: 12px 20px;

    font-family: $rxc-sun-default-sofia-font;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
  }

  &__missingValueError {
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 400;
    font-size: 12px;
    color: #e32118;
    margin-top: 10px;
    align-self: flex-start;

    @include rxc-mobile-only {
      display: none;
    }
  }

  &__missingValueErrorMobile {
    @include rxc-desktop-only {
      display: none;
    }
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 400;
    color: #e32118;
    align-self: flex-start;
    margin-top: 10px;
    font-size: 14px;
  }
}

.CustomPhoneInput {
  &__widthMatching {
    padding: 0 20px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 343px;
    height: 48px;
    border: 1px #d8d8d8 solid;
    border-radius: 4px;

    padding: 12px 20px;

    &__label {
      position: relative;
      left: 7%;
      padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
      margin: calc(0.5rem * 0.75 + 3px) calc(0.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0.25rem, -65%) scale(0.8);
      transform-origin: 0 0;
      background: white;
      transition: transform 120ms ease-in;
      line-height: 1.2;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input {
      border: none;
    }
    input:focus {
      outline-width: 0;
    }
  }

  &__countryCode {
    width: 10%;

    &__inputWrapper {
      display: flex;
    }
  }

  &__phoneNumber {
    width: 100%;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: $rxc-sun-default-sofia-font;
  font-weight: 400;
  font-size: 16px;
  color: #767676;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: $rxc-sun-default-sofia-font;
  font-weight: 400;
  font-size: 16px;
  color: #767676;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: $rxc-sun-default-sofia-font;
  font-weight: 400;
  font-size: 16px;
  color: #767676;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: $rxc-sun-default-sofia-font;
  font-weight: 400;
  font-size: 16px;
  color: #767676;
}

input.SearchBy__phoneNumber:focus {
  font-family: $rxc-sun-default-sofia-font;
  outline-width: 0;
}

.SearchResult {
  font-family: $rxc-sun-default-sofia-font;
  margin-top: 15px;
  padding-bottom: 23px;

  &__changeButtonMobile {
    display: none;
    @include rxc-mobile-only {
      display: block;
      text-align: right;
      width: 100%;
      margin-right: 16px;
    }

    &__linkText {
      color: #557b92;
      font-family: $rxc-sun-default-sofia-font;
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
    }
  }

  &__button {
    width: fit-content;
    height: 32px;
    padding: 0 16px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 100px;
    background-color: white;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 500;
    text-align: center;
    color: $rxc-blue-skin-default;
    border: 1px solid $rxc-blue-skin-default;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.5px;

    @include rxc-mobile-only {
      background-color: #f6f6f6;
    }

    &:hover {
      background: rgba(85, 123, 146, 0.05);
      box-shadow: 0 0 0 1px $rxc-blue-skin-default;
      background-color: rgba(85, 123, 146, 0.05) !important;
    }

    &.mobileHide {
      @include rxc-mobile-only {
        display: none;
      }
    }

    &.clinic {
      left: 50%;
      transform: translateX(-80%);
    }
  }

  table {
    border: 1px solid #d8d8d8;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  table tr {
    background-color: white;
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  @include rxc-mobile-only {
    table tr:nth-child(even) {
      background-color: white;

      td {
        background-color: white;
        &::before {
          background-color: white;
        }
      }

      button {
        background-color: white;
      }
    }
  }

  table th {
    background-color: #f2f2f2;
  }
  table th,
  table td {
    padding: 0.625em;
    text-align: left;
    font-size: 12px;
  }

  table td {
    &.mobileHide {
      @include rxc-mobile-only {
        display: none;
      }
    }
    &.desktopHide {
      @include rxc-desktop-only {
        display: none;
      }
    }
  }

  table th {
    font-size: 12px;
    letter-spacing: 0.1em;
    @include rxc-desktop-only {
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 1199px) {
    table {
      border: none;
    }
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      background-color: #f6f6f6;

      th {
        @include rxc-desktop-only {
          font-weight: 600;
        }
      }
    }

    table tr {
      border: none;
      display: block;
      margin-bottom: 0.625em;
      background-color: #f6f6f6;
      word-wrap: break-word;

      button {
        @include rxc-mobile-only {
          position: absolute;
          left: 35px;
          margin: 0;
          width: calc(100% - 70px);
          font-weight: 600;
        }
      }
    }

    table td {
      border: none;
      display: block;
      font-size: 0.8em;
      text-align: center;
      background-color: #f6f6f6;

      @include rxc-mobile-only {
        text-align: left;
        display: flex;
      }
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      background-color: #f6f6f6;

      @include rxc-mobile-only {
        width: 30%;
        min-width: 30%;
        margin-right: 20px;
      }
    }

    table td:nth-last-child(2)::before {
      @include rxc-mobile-only {
        height: 50px;
      }
    }

    table td:last-child {
      border-bottom: 0;
      @include rxc-mobile-only {
        height: 60px;
      }
    }

    table td.desktopHide:last-child {
      border-bottom: 0;
      @include rxc-mobile-only {
        height: 40px;
      }
    }
  }
  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  }
}

.changeSubmissionMethod {
  color: $rxc-blue-skin-default;
  font-family: $rxc-sun-default-sofia-font;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
}

.addManually {
  &__container {
    display: inline;
    &__plainText {
      font-family: $rxc-sun-default-sofia-font;
      font-size: 12px;
      color: #222222;
      margin-top: 25px;
    }
    &__linkText {
      color: $rxc-blue-skin-default;
      font-family: $rxc-sun-default-sofia-font;
      text-decoration: underline;
      cursor: pointer;
      width: fit-content;
    }
  }
}

.CustomCheckbox {
  &__checkbox {
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid rgb(118, 118, 118);
    min-width: 16px;
    height: 16px;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &.marginTop {
      margin-top: 4px;
    }

    &.checked {
      background: black;
      border: none !important;

      &:after {
        content: "" !important;
        position: absolute !important;
        width: 10px;
        height: 10px;
        top: 3px !important;
        left: 3px !important;
        background-size: contain;
        background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/check.png);
        background-repeat: no-repeat;
      }
    }
    &:hover {
      border: 1px solid rgb(90, 90, 90);
    }
  }
}
.Separator {
  display: block;
  width: 100%;
  height: 1px;
  margin: 5% 0;
  background-color: $rxc-grey-separator;
}

.DoctorNotFound {
  &__box {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 4px;
    margin-top: 20px;
    justify-content: center;

    &__container {
      display: flex;
      flex-flow: column;
      row-gap: 15px;
      align-items: center;
      justify-content: center;
      font-family: $rxc-sun-default-sofia-font;
      font-size: 14px;
      padding-top: 30px;
      padding-bottom: 30px;

      &__img {
        width: 46px;
        height: 46px;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2248%22%20height%3D%2246%22%20viewBox%3D%220%200%2048%2046%22%20fill%3D%22none%22%3E%3Crect%20x%3D%2223%22%20y%3D%2215%22%20width%3D%222%22%20height%3D%2218%22%20fill%3D%22black%22%2F%3E%3Crect%20x%3D%2223%22%20y%3D%2237%22%20width%3D%222%22%20height%3D%222%22%20fill%3D%22black%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M25.8899%201.168L46.7769%2042.941C47.4799%2044.346%2046.4579%2046%2044.8869%2046H3.11393C1.54193%2046%200.520934%2044.346%201.22293%2042.941L22.1099%201.168C22.4989%200.389%2023.2499%200%2023.9999%200C24.7499%200%2025.5009%200.389%2025.8899%201.168ZM44.8859%2044C44.9129%2044%2044.9499%2044%2044.9829%2043.946C45.0159%2043.892%2044.9999%2043.859%2044.9879%2043.836L24.1019%202.063L24.1009%202.06107C24.0843%202.02976%2024.0685%202%2023.9999%202C23.9313%202%2023.9156%202.02976%2023.899%202.06107L23.8979%202.063L3.01193%2043.836C2.99993%2043.859%202.98393%2043.892%203.01693%2043.946C3.05093%2044%203.08693%2044%203.11393%2044H44.8859Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &__plainText {
        text-align: center;
        width: 100%;
        justify-content: center;
        display: flex;
      }
      &__linkText {
        color: #557b92;
        font-family: $rxc-sun-default-sofia-font;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
}

.SearchLoader {
  &__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 24px;
    margin-top: 45px;
    border: solid 1px #222222;
    border-radius: 8px;
    min-height: 160px;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 40px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}
