.UploadTableReadOnly {
  &__container {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border: 1px solid #b0b0b0;
    transition: all 0.2s ease-in-out;
    margin: 0px 0px 24px 0px;

    &__previewLabel {
      color: #222;
      font-family: "sofia-pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
    }

    &__fileInfo {
      display: flex;

      &__fileName {
        color: #222;
        font-family: "sofia-pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__uploadDifferent {
        color: #557b92;
        font-family: "sofia-pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-decoration-line: underline;
        cursor: pointer;
        margin-top: 8px;
      }
    }
  }
}