@import "../../mixins/global";

.CallMyDocCaliforninaModal {
  &__overlay {
    position: absolute;
    background-color: rgba(51, 51, 51, 0.6);
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__close {
    display: block;
    margin-left: auto;
    content: "";
    background: image-set(
      url(#{$globalImageUrl}xclose.png) 1x,
      url(#{$globalImageUrl}xclose@2x.png) 2x,
      url(#{$globalImageUrl}xclose@3x.png) 3x
    );
    background: -webkit-image-set(
      url(#{$globalImageUrl}xclose.png) 1x,
      url(#{$globalImageUrl}xclose@2x.png) 2x,
      url(#{$globalImageUrl}xclose@3x.png) 3x
    );
    background-repeat: no-repeat;
    color: transparent;
    width: 24px;
    height: 24px;
    background-size: 100%;
    align-self: center;
    cursor: pointer;
  }
  &__header {
    width: 100%;
  }
  &__searchResult {
    @include rxc-desktop-only {
      overflow-y: auto;
    }
  }
  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: fit-content;
    background-color: white;
    z-index: 101;
    display: block;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include rxc-desktop-only {
      width: 60%;
      height: 70%;
    }

    @include rxc-mobile-only {
      width: 90%;
      height: 90%;
      overflow-y: scroll;
    }

    &__title {
      font-family: "minerva-modern";
      font-size: 28px;
      font-weight: 400;
      color: #1f1f24;
      text-align: start;
      padding: 5px 0 15px 0;

      @include rxc-mobile-only {
        display: none;
        font-size: 20px;
        text-align: center;
      }
    }

    &__text {
      text-align: center;
      font: 14px "sofia-pro";
    }

    &__buttons {
      display: flex;

      @include rxc-mobile-only {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
      }
    }

    &__continueButton {
      margin-bottom: 15px;
      height: 35px;
      border-radius: 18px !important;
      background-color: #557b92 !important;
      color: #ffffff !important;
      border: none;
      text-transform: uppercase;
      font-size: 14px !important;
      border: 2px solid $rxc-blue-color-1 !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        margin-top: 40px;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-right: 20px;
        font-size: 14px !important;
        width: fit-content;
        margin-top: 20px;
        padding: 0px 30px 0px 32px;
      }
      &:hover {
        background-color: $rxc-blue-color-2 !important;
        border: 2px solid $rxc-blue-color-2 !important;
        box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
          0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
      }
    }

    &__cancelButton {
      margin-top: 20px;
      margin-bottom: 15px;
      height: 35px;
      border-radius: 18px !important;
      background-color: #ffffff !important;
      color: #557b92 !important;
      border: none;
      text-transform: uppercase;
      font-size: 14px !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-left: 20px;
        font-size: 14px !important;
        width: fit-content;
        padding: 0px 30px 0px 32px;
      }
      &:hover {
        box-shadow: 0 0 0 1px $rxc-blue-skin-default;
        background-color: rgba(85, 123, 146, 0.05) !important;
      }
    }
  }
}
.CallMyDocCaliforninaModal__container .SearchBy__container.california {
  width: 100%;
  @include rxc-mobile-only {
    width: 90%;
    margin: 0 auto;
  }
}
.CallMyDocCaliforninaModal__container
  .SearchBy__container.california
  .SearchBy__title {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.5px;
  @include rxc-mobile-only {
    font-size: 20px;
  }
}
.CallMyDocCaliforninaModal__container .optionsSlider__container {
  display: flex;
  align-items: center;
}
.CallMyDocCaliforninaModal__container .SearchBy__searchContainer {
  display: flex;
  justify-content: center;
  @include rxc-desktop-only {
    flex-wrap: wrap;
  }
}
.CallMyDocCaliforninaModal__container .addManually__container__plainText {
  font-size: 14px;
  @include rxc-desktop-only {
    margin-top: 0px;
  }
}
.CallMyDocCaliforninaModal__container
  .SearchBy__searchContainer
  .addManually__container {
  @include rxc-desktop-only {
    width: 100%;
    text-align: center;
  }
}
.CallMyDocCaliforninaModal__container .SearchBy__container .input__field {
  width: 100%;
}
.CallMyDocCaliforninaModal__container .SearchBy__withChildren {
  position: relative;
  height: 60px;
}

.CallMyDocCaliforninaModal__container
  .SearchBy__withChildren
  .SearchBy__clinicContainer__button {
  position: absolute;
  transform: translateX(-50%);
}

.CallMyDocCaliforninaModal__container .SearchBy__clinicContainer__button {
  @include rxc-mobile-only {
    left: 50%;
    transform: translateX(-50%);
  }
}
.CallMyDocCaliforninaModal__container
  .SearchBy__withChildren
  .addManually__container {
  position: absolute;
  right: 0;
  @include rxc-desktop-only {
    top: 50%;
    transform: translateY(-50%);
  }
}
.CallMyDocCaliforninaModal__container thead th {
  background-color: white;
}
.california .stateInputMobileMaxWidth {
  @include rxc-mobile-only {
    max-width: unset !important;
  }
}
.SearchBy__withChildren {
  @include rxc-mobile-only {
    height: 100px !important;
    width: 100%;
    justify-content: center;
    display: flex;
  }
}
.SearchBy__withChildren .SearchBy__clinicContainer__button {
  @include rxc-mobile-only {
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
.SearchBy__withChildren .addManually__container {
  @include rxc-mobile-only {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
  }
}
