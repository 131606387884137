@import "../../../mixins/global.scss";

.TransitionColor {
  &__container {
    display: flex;

    @include rxc-desktop-only {
      flex-flow: row wrap;
      row-gap: 32px;
      column-gap: 32px;
      margin-right: -16px;
    }

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 16px;
    }
  }

  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: $rxc-grey2-sun-default;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-sun-default-sofiaBold-font;
      left: 0px;
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 85px 10px 85px;
    border-radius: 100px;
    background-color: #557b92;
    border: none;
    position: relative;
    z-index: 1;
    font-size: $rxc-sun-default-14-fontSize;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: $rxc-blue-color-2 !important;
      box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
        0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
    }

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      @include rxc-mobile-only {
        top: 25px;
        left: 0px;
        position: relative;
      }
      @include rxc-desktop-only {
        left: 50%;
        transform: translateX(-50%);
        bottom: 95px;
      }
    }
  }

  &__card {
    height: 121px;
    width: 133px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0px 16px;
    border-radius: 8px;
    border: solid 2px #d8d8d8;
    background-color: #ffffff;
    cursor: pointer;

    @include rxc-mobile-only {
      min-height: 121px;
      min-width: 133px;
    }

    &.selected {
      border: solid 2px #222222;
    }

    &:hover {
      border: solid 2px #222222;
    }

    &.disabled {
      cursor: default;
      border: unset;
      background-color: #f2f4f5;
      opacity: 60%;
    }

    &__img {
      width: 44px;
      height: 67px;
    }
    &__text {
      flex-grow: 0;
      font-family: $rxc-sun-default-sofia-font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #222222;
      margin: 0px;
      white-space: nowrap;

      & p {
        margin: 0;
      }
    }
  }
}

.TransitionColor__container .SkipContainer {
  &__container {
    display: inherit;
    flex-flow: inherit;
    row-gap: inherit;
    column-gap: inherit;

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
