@import "../../mixins/global";

.HeaderPrice {
  &__container {
    display: flex;
    font-family: "Mulish";
    font-style: normal;

    @include rxc-mobile-only {
      width: 100%;
      align-self: center;
      justify-content: center;
    }

    &__label {
      align-self: center;
      margin-right: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      p {
        margin: 0 !important;
      }

      &__frameLensPrice {
        @include rxc-mobile-only {
          min-height: 0vw;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }

      &__price {
        @include rxc-mobile-only {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    &__value {
      margin-left: 4px;
      box-sizing: border-box;

      @include rxc-mobile-only {
        align-self: center;
        text-transform: uppercase;
      }
    }
    &__discountvalue {
      margin-left: 4px;
      box-sizing: border-box;
      color: $rxc-grey2-sun-default;

      @include rxc-mobile-only {
        align-self: center;
        text-transform: uppercase;
        text-decoration: line-through;
      }
    }

    & .StepPrice__container__inner {
      display: flex;
      align-items: baseline;

      &__strikePrice {
        padding-top: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      &__price {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
      }
    }

    & .Loader__container {
      width: 15px;
      height: 15px;

      & .Loader__icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__insurance {
    @include rxc-mobile-only {
      text-align: left;
      color: #557b92 !important;
      text-decoration: underline !important;
      margin-top: unset !important;
    }

    &__insuranceEnabled {
      color: #247534;
    }
  }
}
