@import "../../mixins/global";

.AddMyDoctorModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__missingValueError {
      display: unset !important;
      font-family: $rxc-sun-default-sofia-font;
      align-self: flex-start;
      font-weight: 400;
      font-size: 12px;
      color: #e32118;
    }

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 40px 73px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;
      width: 50%;

      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }
    &__title {
      color: #1f1f24;
      margin-bottom: 40px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.16px;
      font: 28px $rxc-sun-default-minerva-font !important;

      @include rxc-mobile-only {
        position: absolute;
        font: 16px $rxc-sun-default-sofia-font !important;
        text-transform: uppercase;
        font-weight: 600 !important;
        margin-bottom: 0;
        top: 20px;
        left: 24px;
      }
    }
    &__exit {
      position: absolute;
      top: 22px;
      right: 22px;
      cursor: pointer;
      min-width: 24px;
      height: 24px;
    }
    &__inputContainer {
      display: flex;
      flex-flow: row;
      column-gap: 25px;
      width: 100%;

      @include rxc-mobile-only {
        margin-top: 40px;
        flex-wrap: wrap;
        margin-bottom: 16px;
      }

      .SearchBy {
        &_numberContainer {
          position: relative;
          width: 100%;

          @include rxc-mobile-only {
            margin-right: 0;
            margin-bottom: 0;
          }

          .PhoneInputInput {
            width: 100%;
          }
        }
        &_numberContainerError {
          position: relative;
          width: 100%;

          @include rxc-mobile-only {
            margin-right: 0;
            margin-bottom: 0;
          }

          .PhoneInputInput {
            border: 1px solid #e32118;
            width: 100%;
          }
        }
        &_numberContainerGreen {
          position: relative;
          width: 100%;

          @include rxc-mobile-only {
            margin-right: 0;
            margin-bottom: 0;
          }

          .PhoneInputInput {
            border: 1px solid #247534;
            width: 100%;
          }

          .PhoneInput {
            &:after {
              content: "";
              width: 20px;
              height: 20px;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='%23247534'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.6678 9.2318C16.0595 9.58537 16.1124 10.2159 15.786 10.6402L11.9399 15.6402C11.7739 15.856 11.5317 15.9862 11.2726 15.999C11.0135 16.0117 10.7614 15.9058 10.578 15.7071L8.27036 13.2071C7.90988 12.8166 7.90988 12.1834 8.27036 11.7929C8.63085 11.4024 9.2153 11.4024 9.57579 11.7929L11.1687 13.5186L14.3678 9.35984C14.6941 8.93556 15.2762 8.87824 15.6678 9.2318Z' fill='%23247534'/%3E%3C/svg%3E%0A");
            }
          }

          input {
            padding-right: 50px;
          }
        }
      }

      .PhoneInputCountry {
        display: none;
      }

      .PhoneInput {
        width: 100% !important;
      }

      .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        flex: 1;
        /* The phone number input should shrink
            to make room for the extension input */
        height: 48px;
        width: 100%;
        padding-left: 16px;
        border: 1px #d8d8d8 solid;
        border-radius: 4px !important;

        &__label {
          position: absolute;
          left: 0%;
          top: 0;
          padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
          margin: calc(0.5rem * 0.75 + 3px) calc(2rem * 0.5);
          white-space: nowrap;
          transform: translate(0, 0);
          transform-origin: 0 0;
          background: white;
          line-height: 1.2;
          pointer-events: none;
          font-weight: 400;
          color: #767676;
          font-family: $rxc-sun-default-sofia-font;
        }
        &__labelFloat {
          position: absolute;
          left: 0%;
          top: 0;
          padding: calc(0.7rem * 0.75) calc(0.5rem * 0.5);
          margin: calc(0.5rem * 0.75 + 3px) calc(1.5rem * 0.5);
          white-space: nowrap;
          transform: translate(0.25rem, -65%) scale(0.8);
          transform-origin: 0 0;
          background: white;
          transition: transform 120ms ease-in;
          line-height: 1.2;
          pointer-events: none;
          font-family: $rxc-sun-default-sofia-font;
        }
      }
    }
    &__buttonsContainer {
      margin-top: 44px;
      margin-bottom: 8px;
      width: 100%;
      height: 32px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        width: 100%;
        height: fit-content;
        margin-top: 30px;
      }

      &__buttonExit {
        font-family: $rxc-sun-default-sofia-font;
        background-color: #557b92;
        border: none;
        border-radius: 100px;
        padding: 0 32px;
        height: 40px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 0.5px;

        @include rxc-mobile-only {
          font-weight: 500;
          min-height: 48px;
          height: fit-content;
          max-width: 180px;
        }
        &:hover {
          background-color: $rxc-blue-color-2 !important;
          box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
            0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
        }
      }
    }

    &__save {
      font-size: 16px;
      font-weight: 600;
      color: #557b92;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 24px;
      font: 16px "sofia-pro-bold", Verdana, Arial, sans-serif;
    }
  }
  .input {
    position: relative;
    font-family: $rxc-sun-default-sofia-font;
    width: 100%;
    margin-bottom: 20px;
    &__container {
      display: flex;
      column-gap: 16px;
    }

    &.valid {
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='%23247534'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.6678 9.2318C16.0595 9.58537 16.1124 10.2159 15.786 10.6402L11.9399 15.6402C11.7739 15.856 11.5317 15.9862 11.2726 15.999C11.0135 16.0117 10.7614 15.9058 10.578 15.7071L8.27036 13.2071C7.90988 12.8166 7.90988 12.1834 8.27036 11.7929C8.63085 11.4024 9.2153 11.4024 9.57579 11.7929L11.1687 13.5186L14.3678 9.35984C14.6941 8.93556 15.2762 8.87824 15.6678 9.2318Z' fill='%23247534'/%3E%3C/svg%3E%0A");
      }
    }

    &__label {
      position: absolute;
      left: 0%;
      top: 0;
      padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
      margin: calc(0.5rem * 0.75 + 3px) calc(1.5rem * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: white;
      transition: transform 120ms ease-in;
      line-height: 1.2;
      font-weight: 400;
      color: #767676;
    }
    &__field {
      width: 100%;
      box-sizing: border-box;
      display: block;
      height: 48px;
      border: 1px #d8d8d8 solid;
      border-radius: 4px !important;
      padding-left: 15px;
      background: transparent;
      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.25rem, -65%) scale(0.8);
          color: #222222;
        }
      }

      &.error {
        border: 1px solid #e32118;
      }

      &.green {
        border: 1px solid #247534;
        padding-right: 50px;
      }
    }
  }
}
