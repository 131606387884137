@import "../../mixins/global";

.StepInReview {
  display: block;

  &__mainContainer {
    display: flex;
    width: 100%;

    &__contentContainer {
      display: flex;
      word-break: break-word;
      width: 100%;
      margin-bottom: 4px;

      &__titleWrapper {
        display: flex;
        justify-content: space-between;
        width: 104px;
      }

      &__title_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__title {
          color: var(--base-gray-dark, var(--color-gray-gray-dark, #767676));
          font-family: "Mulish";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          @include rxc-smaller-mobile-only {
            font: 12px "sofia-pro", Verdana, Arial, sans-serif;
          }
        }

        &__editContainer {
          @include rxc-mobile-only {
            padding-left: 10px;
          }

          @include rxc-smaller-mobile-only {
            padding-left: 4px;
          }

          &__editBtn {
            cursor: pointer;
            font-family: "Mulish" !important;
            font-size: 12px !important;
            font-weight: 600 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.16px;
            text-align: left;
            color: #557b92;
            text-decoration: underline;

            &body {
              font-family: "Mulish" !important;
              font-size: 12px !important;
              font-weight: 600 !important;
            }

            &:first-child {
              text-transform: capitalize;
            }
          }
        }
      }

      &__selectionContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .StepPrice {
          white-space: nowrap;
        }
      }

      &__selection {
        color: var(--base-text-dark, #222);
        font-family: "Mulish";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        &.seeDetails {
          align-items: center;
          display: flex;
        }

        @include rxc-desktop-only {
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }

        &__seeDetails {
          cursor: pointer;
          font-size: 12px;
          margin-left: 8px;
          text-decoration: underline;
        }
      }
    }

    &__priceContainer {
      justify-content: flex-end;
      display: flex;
      width: fit-content;

      .StepPrice__container__inner__strikePrice {
        font-family: "Mulish";
        font-size: 12px;
        line-height: 1.14;

        @include rxc-smaller-mobile-only {
          font-size: 10px;
        }
      }

      .StepPrice__container__inner__price {
        font-family: "Mulish";
        font-size: 16px;
        line-height: 24px;

        @include rxc-smaller-mobile-only {
          font-size: 14px;
        }
      }
    }

    &__includedContainer {
      text-align: right;

      &__label {
        font-size: 16px;
        font-family: "Mulish";
        letter-spacing: 0.5px;
      }
    }
  }
}
