@import "../../mixins/global";

.MissingPrescriptionValues {
  &__overlay {
    position: absolute;
    background-color: rgba(51, 51, 51, 0.6);
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: fit-content;
    background-color: white;
    z-index: 101;
    display: block;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include rxc-desktop-only {
      min-width: 400px;
      max-width: 500px;
    }

    @include rxc-mobile-only {
      width: 90%;
    }

    &__title {
      font-family: "minerva-modern";
      font-size: 28px;
      font-weight: 400;
      color: #1f1f24;
      text-align: start;
      padding: 5px 0 15px 0;

      @include rxc-mobile-only {
        font-size: 20px;
        text-align: center;
      }
    }

    &__text {
      text-align: center;
      font: 14px "sofia-pro";
    }

    &__buttons {
      display: flex;

      @include rxc-mobile-only {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
      }
    }

    &__continueButton {
      margin-bottom: 15px;
      height: 35px;
      border-radius: 18px !important;
      background-color: #557b92 !important;
      color: #ffffff !important;
      border: none;
      text-transform: uppercase;
      font-size: 14px !important;
      border: 2px solid $rxc-blue-color-1 !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        margin-top: 40px;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-right: 20px;
        font-size: 14px !important;
        width: fit-content;
        margin-top: 20px;
        padding: 0px 30px 0px 32px;
      }
      &:hover {
        background-color: $rxc-blue-color-2 !important;
        border: 2px solid $rxc-blue-color-2 !important;
        box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
          0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
      }
    }

    &__cancelButton {
      margin-top: 20px;
      margin-bottom: 15px;
      height: 35px;
      border-radius: 18px !important;
      background-color: #ffffff !important;
      color: #557b92 !important;
      border: none;
      text-transform: uppercase;
      font-size: 14px !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-left: 20px;
        font-size: 14px !important;
        width: fit-content;
        padding: 0px 30px 0px 32px;
      }
      &:hover {
        box-shadow: 0 0 0 1px $rxc-blue-skin-default;
        background-color: rgba(85, 123, 146, 0.05) !important;
      }
    }
  }
}
