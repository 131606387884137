@import "../../mixins/global";


.Tabs {
  font-family: $rxc-clearly-font;

  @include rxc-mobile-only {
    &:not(:first-child) {
      border-top: 1px solid $rxc-clearly-border-color;
    }
  }

  &__header {
    border-bottom: 1px solid $rxc-clearly-border-color;
    background: $rxc-clearly-background-color;
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    li {
      background: $rxc-clearly-background-color;
      font-family: $rxc-clearly-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      border: none;
      width: 100%;
      list-style: none;
      white-space: nowrap;
      cursor: pointer;

      a {
        padding: 8px 16px;

        width: 100%;
        height: 100%;
        display: block;

        @include rxc-desktop-only {
          padding: 8px 20px;

          &:focus,
          &:active {
            outline: 1px solid black;
            border-right: 1px solid black;

            &:last-child {
              border: none;
            }
          }
        }
      }

      &:not(:last-child) {
        border-right: 1px solid $rxc-clearly-border-color;
      }

      @include rxc-desktop-only {
        &:hover {
          outline: 1px solid black;
          border-right: 1px solid black;

          &:last-child {
            border: none;
          }
        }
      }
    }
  }

  &__content {
    font-family: $rxc-clearly-font;
    padding: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @include rxc-desktop-only {
      padding: 24px;
    }

    .TabPanel {
      p {
        margin: 0 0 16px 0;

        &.bold {
          margin-bottom: 4px;
          font-weight: 700;
        }
      }
    }
  }
}