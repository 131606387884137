@import "../../mixins/global";

.StepPrice {
  & {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    display: flex;
    text-align: center;
    align-items: baseline;

    &__insuranceLoading {
      align-items: center;
    }

    &__insuranceText {
      font: 16px $rxc-sun-default-sofia-font;
      color: #247534 !important;
    }

    &__inner {
      display: flex;
      align-items: center;
      color: #222222 !important;

      @include rxc-mobile-only {
        text-align: end;
      }

      &__strikePrice {
        text-decoration: line-through;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        padding-top: 4px;
        color: #222222 !important;

        @include rxc-desktop-only {
          margin-right: 6px;
          margin-bottom: 2px;
        }

        @include rxc-mobile-only {
          display: inline-flex;
        }
      }
      &__price {
        color: #222222 !important;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: right;

        @include rxc-mobile-only {
          display: inline-flex;
          margin-left: 4px;
        }
      }
      &__free {
        font-family: "sofia-pro";
        font-size: 16px;
        text-align: right;
        letter-spacing: 0.5px;
        color: $rxc-grey2-sun-default !important;
      }
      &__green {
        color: #247534 !important;
      }
      &__GVPLabel {
        margin-right: 8px;
        font: 12px $rxc-sun-default-sofia-font;
      }

      &__startingAtLabel {
        margin-right: 8px;
        font: 12px $rxc-sun-default-sofia-font;
      }

      &__labelContainer {
        display: flex;
        align-items: baseline;
      }
    }
  }

  &__vatIncluded {
    position: absolute;
    transform: translateY(22px);
    font: 12px $rxc-sun-default-sofia-font;
    font-weight: normal;
    color: #6d6d6d;
  }
}
