@import "../../mixins/global";

.optionsSlider {
  &__slider {
    &__container {
      width: fit-content;
      margin-bottom: 16px;
      border-radius: 100px;
      border: solid 1px $rxc-blue-skin-default;
      @include rxc-mobile-only {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 16px auto 10px auto;
      }
      &__btn {
        width: fit-content;
        background-color: $rxc-white-color-1;
        border-radius: 100px;
        border: 3px solid transparent;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-blue-skin-default;
        padding: 8px 16px 8px 16px;
        transition: 0.3s;
        cursor: pointer;
        @include rxc-mobile-only {
          box-sizing: border-box;
          height: fit-content;
          font-size: $rxc-sun-default-12-fontSize;
        }

        &.clicked {
          color: $rxc-white-color-1;
          background-color: $rxc-blue-skin-default;
          border-radius: 100px;
          font-size: $rxc-sun-default-14-fontSize;
          border: 3px solid $rxc-blue-skin-default;
          padding: 8px 16px 8px 16px;
          @include rxc-mobile-only {
            width: fit-content;
            font-size: $rxc-sun-default-12-fontSize;
            height: fit-content;
          }
        }
        &.clicked:first-child {
          @include rxc-mobile-only {
            transform: translateX(-3px);
          }
        }
        &.clicked:last-child {
          @include rxc-mobile-only {
            transform: translateX(3px);
          }
        }
      }
    }
  }

  &__container {
    flex-flow: column nowrap;
    @include rxc-desktop-only {
      padding: 10px 0;
    }

    @include rxc-mobile-only {
      padding: 15px 0px 0px 0;
    }
  }
}
