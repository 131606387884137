@import "../../mixins/global.scss";

.SkipContainer {
  &.selected {
    &.Step-Type,
    &.Step-Brand,
    &.Step-TreatmentsFamily,
    &.Step-Treatments,
    &.Step-Thickness,
    &.Step-AddOns {
      & .StepCard__card {
        border: none;
        cursor: unset;
        box-shadow: none;
        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  &__container {
  }

  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: $rxc-grey2-sun-default;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-sun-default-sofiaBold-font;
      left: 0px;
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 85px 10px 85px;
    border-radius: 100px;
    background-color: $rxc-grey2-sun-default;
    border: none;
    position: relative;
    z-index: 1;
    font-size: $rxc-sun-default-14-fontSize;
    font-family: $rxc-sun-default-sofia-font;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background-color: $rxc-grey-color-9 !important;
      box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
        0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
    }

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      @include rxc-mobile-only {
        bottom: 22%;
        left: 0px;
      }
      @include rxc-desktop-only {
        left: 50%;
        transform: translateX(-50%);
        bottom: 95px;
      }
    }
  }
}
