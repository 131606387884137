@import "../../mixins/global.scss";

.vmmv-container-rxc {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 50vw;
  z-index: 99999999;

  .vmmv {
    width: 100%;
    height: 100%;
  }

  .line {
    border: none !important;
    opacity: 1 !important;
  }
  #vm-layout {
    background-color: white !important;
    @include rxc-desktop-only {
      max-width: unset;
    }
  }

  .close {
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 100;
    padding: 8px 16px;
    border: 1px solid #000000;
    border-radius: 100px;
    background-color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Medium";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    &__icon {
      height: 16px;
      width: 20px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg id='191'%3e%3cpath id='Vector' d='M15.8602 6.84035C15.5552 5.67802 14.7809 4.92068 13.7292 4.76202C13.2939 4.70468 12.8159 4.67035 12.3336 4.66835V4.66602H3.66691V4.66835C3.18257 4.67068 2.70291 4.70502 2.26491 4.76302C1.21957 4.92102 0.44524 5.67835 0.14024 6.84068C-0.25276 8.33902 0.219907 10.0693 1.23924 10.865C1.63557 11.1743 2.11457 11.331 2.63791 11.331C2.94991 11.331 3.27724 11.2753 3.61257 11.1627C5.14424 10.6487 6.60424 9.05001 7.19224 7.27868C7.65724 6.92668 8.34191 6.92635 8.80724 7.27835C9.39124 9.04502 10.8536 10.648 12.3876 11.1627C12.7229 11.275 13.0502 11.331 13.3622 11.331C13.8856 11.331 14.3646 11.174 14.7609 10.865C15.7806 10.069 16.2529 8.33902 15.8602 6.84035ZM6.64524 6.78835C6.19424 8.47702 4.82991 10.0513 3.40091 10.531C2.92057 10.692 2.22757 10.7893 1.64991 10.3397C0.84124 9.70802 0.461574 8.24568 0.785574 7.00968C0.92324 6.48502 1.31124 5.58135 2.35891 5.42302C2.80991 5.36368 3.25024 5.33435 3.66724 5.33202V5.33268H3.80591C5.04991 5.34402 6.06491 5.60268 6.47324 6.05435C6.65957 6.26068 6.71357 6.49702 6.64524 6.78835ZM7.33224 6.45068C7.30891 6.13702 7.18757 5.85068 6.96724 5.60735C6.87524 5.50568 6.76591 5.41602 6.64657 5.33302H9.35324C9.23424 5.41602 9.12457 5.50568 9.03291 5.60735C8.81357 5.85002 8.69224 6.13568 8.66891 6.45102C8.45591 6.37502 8.23024 6.33268 8.00024 6.33268C7.77024 6.33268 7.54524 6.37502 7.33224 6.45068ZM14.3509 10.3397C13.7732 10.7897 13.0799 10.6923 12.5999 10.531C11.1709 10.0513 9.80624 8.47735 9.35757 6.79802C9.28691 6.49702 9.34091 6.26068 9.52757 6.05435C9.93657 5.60235 10.9536 5.34368 12.1969 5.33268H12.3336V5.33235C12.7492 5.33468 13.1872 5.36268 13.6359 5.42202C14.6896 5.58135 15.0776 6.48502 15.2152 7.00968C15.5392 8.24535 15.1596 9.70802 14.3509 10.3397Z' fill='black'/%3e%3c/g%3e%3c/svg%3e");
      background-position: center center;
      background-size: cover;
      margin: 0px 8px 0px 0px;
    }
    cursor: pointer;
    z-index: 9999999999999;
    color: black;
    position: absolute;
    right: 16px;
    @include rxc-mobile-only {
      top: 4px;
      right: 4px;
      border: none;
      background-color: transparent;
    }
  }

  @include rxc-mobile-only {
    width: 100vw;
    #privacy-agreement {
      padding-inline: 24px;
    }
  }
}
