@import "../../mixins/global";

.WarrantyOptions {
  &__price_container {
    display: flex;
    align-items: center;

    &__text {
      text-decoration: underline;
      display: flex;
      font-family: "sofia-pro";
      cursor: pointer;
      user-select: none;
      font-size: 14px;
    }

    &__digit {
      display: flex;
      align-items: center;
      font-family: "sofia-pro-light";

      & .Price__container__inner__price {
        font-family: "sofia-pro" !important;
        font-size: 12px !important;
      }
    }

    &__addText {
      display: flex;
      font-family: "sofia-pro";
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &__warrantyContainer {
    display: flex;
    min-height: 64px;
    align-items: center;

    .Price__container__inner {
      margin-top: 0px;
      margin-bottom: 0 !important;
    }

    @include rxc-desktop-only {
      margin-top: 16px;
    }

    & input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: #fafafa;
      border: 1px solid #222222;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 7px;
      border-radius: 4px;
      display: inline-block;
      position: unset;
      position: unset;
      margin: 1px 8px 0 4px;
      opacity: unset;
      max-width: 15px;
      max-height: 15px;
    }
    & input[type="checkbox"]:active,
    & input[type="checkbox"]:checked:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
    /* checkbox checked bacgroud color */
    & input[type="checkbox"]:checked {
      background-color: #111111 !important;
      border: 1px solid #222222;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: white;
    }
    & input[type="checkbox"]:checked:after {
      content: "\2714";
      font-size: 14px;
      position: relative;
      top: -11px;
      left: -5.5px;
      color: white;
    }
  }
}

.Tooltip {
  &__tooltip {
    font-family: Roboto;
    font-size: 12px;
    z-index: 1;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 13%;
    max-width: 289px;
    padding: 30px;
    opacity: 1 !important;
    background-color: $rxc-white-color-1;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: #222222;

    @include rxc-mobile-only {
      margin-top: 10%;
    }
  }

  &__text {
    display: flex;
    margin-bottom: 8px;
    white-space: pre-line;
    vertical-align: bottom;
  }
}
.WarrantyOptions__warrantyContainer a {
  margin-top: 10px;
  color: #326fa8;
  text-decoration: underline;
  font: 12px "sofia-pro", Verdana, Arial, sans-serif;
}
