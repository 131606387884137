.ErrorModal {
  &__container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99999999999999;

    &__content {
      position: relative;
      width: 500px;
      height: 300px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0px 0px 2.94545px rgba(40, 41, 61, 0.04),
        0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
      padding: 40px, 24px;

      &__title {
        position: relative;
        width: 90%;
        font-family: "MinervaModern";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #222222;
      }

      &__description {
        position: relative;
        width: 90%;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: block;
        text-align: center;
        letter-spacing: 0.5px;
        color: #222222;

        a {
          text-decoration: underline;
          color: #222222;
          font-weight: bold;
        }
      }

      &__button {
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 32px;
        gap: 10px;
        color: white;
        background: #557b92;
        border-radius: 100px;
        border: none;
        text-transform: uppercase;
      }
    }
  }
}
