.PromoBanner {
  position: relative;
  width: 100%;
  &__badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &__desktop,
    &__mobile {
      & div {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}
