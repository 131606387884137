@import "../../mixins/global";


.PupillaryDistance {
  &__titleContainer {
    font-family: "Mulish";
    font-style: normal;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin: 0 !important;
      cursor: pointer;
      text-decoration: underline;
      max-width: fit-content;
      color: #1c4da1;

      &::before {
        content: "";
        height: 16px;
        width: 16px;
        min-width: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cg clip-path='url(%23clip0_1395_5061)'%3E%3Cpath d='M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z' fill='%231C4DA1'/%3E%3Cpath d='M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z' fill='%231C4DA1'/%3E%3Cpath d='M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z' fill='%231C4DA1'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1395_5061'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }

  &__subtitleContainer {
    margin-bottom: 16px;
    font-family: SofiaPro;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    text-align: left;
    color: #222;

    & a {
      color: #557b92;
      text-decoration: underline;
    }
  }

  &__checkboxContainer {
    &__noMargin {
      display: flex;
      align-items: center;
      margin-top: 2.5px;
      margin-left: 0px !important;

      @include rxc-mobile-only {
        margin-top: 16px !important;
      }
    }

    &__margin {
      display: flex;
      align-items: center;
      margin-left: 20px !important;
      margin-top: 2.5px;
    }
  }

  &__checkBoxText {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
  }

  &__optionsContainer {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__table {
      margin: 24px 0px;

      &.single {
        >div {
          height: 40px;

          select {
            background-position: right 10px bottom 12px !important;
          }
        }
      }

      border: 1px solid $rxc-default-border-color;
      display: flex;
      max-width: 100%;

      &__leftCol {
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);
      }

      &__leftLabel {
        border-top: 1px solid $rxc-default-border-color;
        display: grid;
        place-content: center;
      }

      &__col {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);

        &:not(:first-child)>div:not(:first-child) {
          background-color: #fff;
        }

      }

      &__row {
        &:last-child {
          border-top: 1px solid $rxc-default-border-color;
          ;
        }

        &:first-child {
          display: grid;
          place-content: center;
          background: $rxc-default-background-color;
        }
      }

      &>div {
        &:not(:last-child) {
          border-right: 1px solid $rxc-default-border-color;
        }

        width: 128px;


        &>div {
          padding: 8px;
          width: 100%;
          height: 100%;
        }
      }

      &>div:first-child {
        display: grid;
        place-items: center;
        background: $rxc-default-background-color;
      }
    }

    &__select_container {
      position: relative;
      height: 100%;
      background-color: #ffffff;

      input {
        padding-left: 24px !important;
      }

      & select {
        cursor: pointer;
        display: flex;
        justify-content: start;
        width: 100%;
        padding-left: 10px;
        height: 100%;
        background-color: #ffffff;
        color: #333333;
        border-radius: 4px;
        align-items: center;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16px;
        -webkit-appearance: none;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC44NjIyNTQgMC41Mjg1MTRDMC42MDE5MDUgMC43ODg4NjQgMC42MDE5MDUgMS4yMTA5NyAwLjg2MjI1NCAxLjQ3MTMyTDUuNTI4OTIgNi4xMzc5OUM1Ljc4OTI3IDYuMzk4MzQgNi4yMTEzOCA2LjM5ODM0IDYuNDcxNzMgNi4xMzc5OUwxMS4xMzg0IDEuNDcxMzJDMTEuMzk4NyAxLjIxMDk3IDExLjM5ODcgMC43ODg4NjQgMTEuMTM4NCAwLjUyODUxNUMxMC44NzggMC4yNjgxNjUgMTAuNDU1OSAwLjI2ODE2NSAxMC4xOTU2IDAuNTI4NTE1TDYuMDAwMzMgNC43MjM3OEwxLjgwNTA2IDAuNTI4NTE0QzEuNTQ0NzEgMC4yNjgxNjUgMS4xMjI2IDAuMjY4MTY1IDAuODYyMjU0IDAuNTI4NTE0WiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K) !important;
        background: no-repeat;
        background-position: right 2px bottom 3px !important;
        background-size: 16px 16px;
        border: none;

        @include rxc-small-mobile-only {
          width: 100% !important;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #767676;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #767676;
        }
      }
    }
  }

  &__checkboxLabel {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0 !important;
  }

  &__pdValueNotSelectedWarning {
    color: #D51C29;
    font-family: "Mulish";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    margin-bottom: 24px;
    pointer-events: none;
    margin-top: unset;

    &::after {
      content: attr(data-content);
      color: #1C4DA1;
      text-decoration: underline;
      pointer-events: all;
      font-weight: 700;
    }
  }
}