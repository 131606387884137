@import "../../mixins/global.scss";

.showSkeleton {
  .skeletonStep {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;

    .left {
      width: 50%;
      background: #F6F6F6;

      @include rxc-mobile-only {
        display: none;
      }
    }

    .rigth {
      width: 50%;
      background: #fff;
      padding: 65px 40px 0 40px;
      display: flex;
      flex-direction: column;

      @include rxc-mobile-only {
        width: 100vw;
        padding: 90px 16px 0 16px;
        position: relative;

        .header {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 60px;
          box-shadow: 0 1.5px 5.9px 0 rgba(96, 97, 112, 0.16), 0 0 2.9px 0 rgba(40, 41, 61, 0.08);
        }
      }

      .line {
        background: linear-gradient(270.02deg, #D9D9D9 4%, #EBEBEB 98.9%);
        border-radius: 100px;
        position: relative;
        overflow: hidden;
        @include skeleton-animation();
      }



      .title {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .line:nth-child(1) {
          width: 50%;
          height: 25px;
          margin-bottom: 12px;
        }

        .line {
          width: 85%;
          height: 15px;
        }

        margin-bottom: 34px;
      }


      .cards {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .card {
          padding: 16px;
          display: flex;
          border: 1px solid #D8D8D8;
          border-radius: 8px;
          gap: 24px;

          .image {
            border-radius: 50%;
            width: 126px;
            height: 126px;
            background: linear-gradient(270.02deg, #D9D9D9 4%, #EBEBEB 98.9%);
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            @include skeleton-animation();

            @include rxc-mobile-only {
              width: 78px;
              height: 78px;
            }
          }

          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            flex-grow: 1;

            .line:nth-child(1) {
              width: 30%;
              height: 20px;
            }

            .line {
              width: 100%;
              height: 15px;
            }
          }
        }
      }
    }
  }
}