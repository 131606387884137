@import "../../mixins/global";

.BottomBarTotalPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0px;
  padding: 0 !important;
  font-family: "Mulish" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  &__pricerow {
    align-self: flex-end;
    align-items: baseline;
    display: flex;
    width: max-content;
    align-items: center;

    &__label {
      p {
        margin: 0px;
        text-transform: uppercase;
      }
    }

    &__price {
      margin-left: 10px;
    }
    &__green {
      color: #247534;
    }
  }
}
