@import "../../mixins/global";

.PaymentInstallmentsModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
    }
    &__popup {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 8px;
      background-color: #ffffff;
      min-height: 300px;
      width: 860px;
      padding: 40px 24px 32px 24px;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;

      @include rxc-mobile-only {
        overflow: auto;
        padding: 40px 16px 20px 16px;
        margin: 0;
        width: calc(100% - 2 * 16px);
        height: auto;
        max-height: 90vh;
      }

      &__closeContainer {
        position: absolute;
        top: 20px;
        right: 20px;

        /* @include rxc-mobile-only {
          margin-bottom: 10px;
        } */
        &__close {
          display: block;
          content: "";
          background: image-set(
            url(#{$globalImageUrl}xclose.png) 1x,
            url(#{$globalImageUrl}xclose@2x.png) 2x,
            url(#{$globalImageUrl}xclose@3x.png) 3x
          );
          background: -webkit-image-set(
            url(#{$globalImageUrl}xclose.png) 1x,
            url(#{$globalImageUrl}xclose@2x.png) 2x,
            url(#{$globalImageUrl}xclose@3x.png) 3x
          );
          background-repeat: no-repeat;
          color: transparent;
          width: 24px;
          height: 24px;
          background-size: 100%;
          align-self: center;
          cursor: pointer;
          @include rxc-mobile-only {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        &__title {
          font-family: "minerva-modern";
          font-size: 28px;
          font-stretch: normal;
          font-style: normal;
          line-height: 32px;
          letter-spacing: normal;
          text-align: left;
          color: #222222;
          margin-bottom: 15px;
        }

        &__subtitle {
          font-family: "sofia-pro", sans-serif;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-align: center;
          color: #222222;
          @include rxc-mobile-only {
            white-space: normal;
          }
        }

        &__options_container {
          &__option_box {
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            margin-top: 16px;
            padding: 32px 16px;
            font-family: "sofia-pro";
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #222222;
            flex-direction: row;
            display: flex;
            align-items: center;
            &__logo {
              width: 61px;
              max-height: 42px;
              object-fit: cover;
            }
            &__description {
              width: 80%;
              margin-left: 20px;
              white-space: pre-line;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: 0.16px;
              text-align: left;
              color: #222;
              font-family: "sofia-pro", sans-serif;
              & a {
                color: black;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
