@import "../../../mixins/global.scss";

.Thickness {
  &__filterPill {
    background-color: $rxc-white-color-1;
    max-height: fit-content;
    cursor: pointer;
    max-height: fit-content;
    text-transform: capitalize;
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 100px;
    border: solid 1px $rxc-grey-separator;
    font-size: $rxc-sun-default-12-fontSize;
    white-space: nowrap;
    display: none !important;
    &.selected {
      cursor: pointer;
      text-transform: capitalize;
      padding: 8px 16px;
      margin: 0 16px 0 0;
      color: $rxc-white-color-1;
      background-color: $rxc-grey2-sun-default;
      border-radius: 100px;
      border: solid 1px $rxc-grey2-sun-default;
      font-size: $rxc-sun-default-12-fontSize;
    }
  }
  &__container {
    &__title {
      display: none;
    }
  }
}
