@import "../../mixins/global";

.StepContainer {
  &__container {
    @include rxc-mobile-only {
      min-height: 60vh;
      height: fit-content;
      // padding-bottom: 210px;
    }
    @include rxc-mobile-only {
    }

    &:last-child {
      @include rxc-desktop-only {
        padding-bottom: 24px;
      }
    }

    @include rxc-desktop-only {
      margin: 0 60px 0 40px;
    }
  }
}

.learnMoreButton {
  width: fit-content;
  text-decoration: underline;
  margin-bottom: 16px;
  color: $rxc-blue-skin-default;
  font-family: "Montserrat-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}
