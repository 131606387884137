@import "../../mixins/global.scss";

.PrescriptionFromMyAccountCard {
  font-family: $rxc-clearly-font;
  padding: 16px;
  padding-bottom: 53px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 8px;
  position: relative;
  color: #000000;
  cursor: pointer;

  @include rxc-mobile-only {
    flex-direction: column;
    align-items: flex-start;
  }

  &.expired {
    background: #f6f6f6;
    cursor: initial;

    span {
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 6px;
    }

    p {
      cursor: initial;
    }
  }
  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 0px 8px 0px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__issueDate {
    font-size: 14px;
    line-height: 21px;
  }
  &__lastUpdate {
  }
  &__showPrescription {
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 0;
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    left: 16px;
    color: #1c4da1;

    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__olderThanYearBanner {
    display: none;
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    gap: 8px;
    background: #000000;
    border-radius: 4px;
    color: white;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 100%;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__separator {
    height: 1px;
    background-color: #b8b8b8;
    margin-top: 16px;
  }
}
