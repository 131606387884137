@import "../../mixins/global";

.StepPriceBadge {
  &__container {
    font: 12px $rxc-sun-default-sofia-font;
    margin-right: 20px;
    color: #244c5a !important;

    @include rxc-mobile-only {
      line-height: 24px;
    }

    &::before {
      content: "\2022";
      width: 4px;
      height: 4px;
      margin-right: 4px;
      border-radius: 100%;
      color: #244c5a !important;
    }
  }
}
