@import "../../mixins/global";

.PrescriptionReviewSeeDetails {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px 24px 24px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;
      width: fit-content;
      min-width: 40%;
      max-height: 85vh;

      @include rxc-mobile-only {
        width: 90%;
        padding: 56px 22px 26px 22px;
        overflow: scroll;
      }

      &.widthUnset {
        @include rxc-desktop-only {
          width: unset !important;
        }
      }

      &.loading {
        height: 50vh;
      }
    }
    &__title {
      color: #1f1f24;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.16px;
      font: 28px $rxc-sun-default-minerva-font !important;
      align-self: center;
      margin-bottom: 18px;

      @include rxc-mobile-only {
        margin-bottom: 16px;
      }
    }
    &__exit {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      @include rxc-mobile-only {
        right: 22px;
        top: 22px;
      }
    }

    &__previewContainer {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 50vh;
        max-width: 100%;
      }

      .ManualTable {
        width: 100%;
      }

      .SearchResult table td {
        @include rxc-mobile-only {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .SearchResult table td:last-child {
    height: max-content;

    @include rxc-mobile-only {
      padding-bottom: 30px;
    }
  }

  .SearchResult table td:first-child {
    @include rxc-mobile-only {
      padding-top: 30px;
    }
  }

  .SearchResult table td::before {
    text-transform: capitalize !important;
  }

  .ManualTable {
    padding: 0 28px;

    @include rxc-mobile-only {
      font-size: min(
        max(9px, calc(0.5625rem + ((1vw - 3.3px) * 15.5556))),
        16px
      );
      min-height: 0vw;
      padding: 0;
    }

    .marginTop {
      margin-top: 16px;
      word-break: break-word;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      &.desktop_table {
        @include rxc-small-mobile-only {
          display: none;
        }
      }
      &.mobile_table {
        @include rxc-desktop-only {
          display: none;
        }
        @include rxc-mobile-only {
          display: none;
        }
        @include rxc-small-mobile-only {
          display: inline-table;
        }
      }
    }

    th,
    td {
      border: 1px solid #d8d8d8;
      padding: 10px;
      max-width: 50px;
    }
  }
}
