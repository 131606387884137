.DragAndResize {
  font-family: monospace;
  border: 1px solid #000;
  resize: both;
  overflow-y: auto;
  overflow-x: visible;
  padding: 5px;
  z-index: 1000;
  background-color: #000;
  color: #f6f6f6;

  &::after {
    position: sticky;
    top: 0;
    left: 100%;
    content: "debug";
    display: inline-block;
    text-align: right;
    padding: 0.5rem;
    background-color: red;
    color: #fff;
    width: max-content;
    border-radius: 5px;
    z-index: 999999;
  }


  .OptionsPanel {
    position: fixed;
    background-color: #fff;
    color: #000;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    border: 1px solid #f6f6f6;

    .checkbox {
      display: flex;
      align-items: center;
      gap: 8px;

      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: relative !important;
        left: unset !important;
        opacity: 1 !important;
      }

      [type="checkbox"]:not(:checked)+label,
      [type="checkbox"]:checked+label {
        position: relative;
        font-size: 14px;
        line-height: unset !important;
        cursor: pointer;
        padding: 0 !important;
      }

      [type="checkbox"]:not(:checked)+label:before,
      [type="checkbox"]:checked+label:before {
        display: none !important;
      }

      [type="checkbox"]:not(:checked)+label:after,
      [type="checkbox"]:checked+label:after {
        display: none !important;
      }
    }

    button {
      padding: 4px 8px;
      cursor: pointer;
    }

    .closeTrigger {
      margin-left: auto;
    }
  }

  .log {
    font-size: 14px !important;

    ul {
      list-style: square;
      padding-inline-start: 30px;

      li {
        button {
          margin-left: 20px;
          cursor: pointer;
          padding: 4px 8px;
        }

        &.log {
          color: limegreen;
        }

        &.error {
          color: red;
        }

        &.warn {
          color: orange;
        }
      }
    }

    pre {
      margin: 1rem 0 !important;

      span {
        color: greenyellow
      }
    }

    .divider {
      height: 1px;
      border: 1px dashed #fff;
    }
  }
}