@import "../../mixins/global";

.StepBadge {
  &__container {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    top: -14px;
    right: 16px;
    width: fit-content;
    height: 24px;
    background: #1C4DA1;
    border-radius: 100px;
    z-index: 100;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;

    @include rxc-mobile-only {
      top: -14px;
      right: 8px;
    }

    &__star {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      top: -14px;
      right: 16px;
      width: 121px;
      height: 24px;
      background: #557b92;
      border-radius: 100px;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        top: -14px;
        right: 8px;
      }

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.98264 3.02867C7.9428 2.91 7.83897 2.823 7.71514 2.80433L5.35964 2.4445L4.3018 0.191667C4.2468 0.0746667 4.1293 0 3.99997 0C3.8708 0 3.75314 0.0746667 3.6983 0.191667L2.6403 2.44467L0.283804 2.80467C0.160471 2.8235 0.0569706 2.9105 0.0173039 3.02883C-0.0225294 3.1475 0.00763727 3.27967 0.0951373 3.36917L1.80997 5.12583L1.40447 7.61117C1.3888 7.70767 1.4163 7.807 1.4798 7.8815C1.54314 7.956 1.63664 7.99917 1.73447 7.99917C1.79114 7.99917 1.8473 7.98467 1.8968 7.95717L3.99997 6.795L6.1053 7.9585C6.14314 7.9795 6.1848 7.99283 6.2278 7.99783C6.2408 7.99917 6.25364 8 6.26647 8C6.35647 8 6.44347 7.9635 6.5068 7.89783C6.57964 7.82217 6.61264 7.7165 6.5958 7.61283L6.18997 5.12583L7.9048 3.36867C7.99214 3.27933 8.0223 3.14733 7.98264 3.02867Z' fill='white'/%3e%3c/svg%3e");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }
    }
  }

  &__badge {
    &:last-child {
      margin-right: 0;
    }

    &_mostPopular {
      display: flex;
      color: white !important;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      z-index: 1;

      letter-spacing: 0.5px;

      @include rxc-mobile-only {
        margin-bottom: 2px;
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }

    &_recommended {
      display: flex;
      color: white !important;
      font-family: "sofia-pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      z-index: 1;
      margin-bottom: 2px;
      //letter-spacing: 0.5px;

      // @include rxc-mobile-only {
      // margin-bottom: 2px;
      // font-family: $rxc-sun-default-sofiaBold-font;
      // font-weight: 600;
      // }
    }

    &_bestPrescription {
      display: flex;
      color: white !important;
      font-family: "sofia-pro";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      z-index: 1;
      margin-bottom: 2px;
      //letter-spacing: 0.5px;

      // @include rxc-mobile-only {
      // margin-bottom: 2px;
      // font-family: $rxc-sun-default-sofiaBold-font;
      // font-weight: 600;
      // }
    }

    &_comingSoon {
      display: flex;
      color: white !important;
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      z-index: 1;

      letter-spacing: 0.5px;

      @include rxc-mobile-only {
        margin-bottom: 2px;
        font-family: $rxc-sun-default-sofiaBold-font;
        font-weight: 600;
      }
    }
  }
}