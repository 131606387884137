@import "../../mixins/global";

.MyAccountPrescriptionDetailModal {
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-family: $rxc-default-sofia-font;

  &__Modal {
    width: 50vw;
    margin: 0 auto;
    padding: 48px 33px 33px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;

    h2 {
      text-align: center;
      margin: 0;
    }

    p {
      svg {
        display: none;
      }

      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin-top: 24px;
      color: #1c4da1;
      text-align: left;
      cursor: pointer;

      /* div { */
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg;base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDE1LjgzMzNIMFYxNi41SDE2VjE1LjgzMzNaIiBmaWxsPSIjMUM0REExIi8+CjxwYXRoIGQ9Ik0xLjk5OCAxNC41QzIuMDQxNjcgMTQuNSAyLjA4NjMzIDE0LjQ5NTcgMi4xMzEzMyAxNC40ODY3TDQuNDY5IDEzLjg3M0M0LjU5OCAxMy44NDczIDQuNzE2NjcgMTMuNzgzNyA0LjgwOTY3IDEzLjY5MDdMMTUuMTM4IDMuMzYyQzE1LjM5ODMgMy4xMDE2NyAxNS4zOTgzIDIuNjc5NjcgMTUuMTM4IDIuNDE5MzNMMTMuNDE0IDAuNjk1MzMzQzEzLjI4NCAwLjU2NSAxMy4xMTMzIDAuNSAxMi45NDI3IDAuNUMxMi43NzIgMC41IDEyLjYwMTMgMC41NjUgMTIuNDcxMyAwLjY5NTMzM0wyLjE0MjY3IDExLjAyNEMyLjA0OTY3IDExLjExNyAxLjk4NiAxMS4yMzU3IDEuOTYwMzMgMTEuMzY0N0wxLjM0NjY3IDEzLjcwMjNDMS4yNjIzMyAxNC4xMjM3IDEuNTkgMTQuNSAxLjk5OCAxNC41Wk0yLjYxMDMzIDExLjUxNUwyLjYxNDMzIDExLjQ5NTdMMTIuOTQyMyAxLjE2NjY3SDEyLjk0M0wxNC42NjY3IDIuODkwNjdMNC4zMzggMTMuMjE5M0w0LjMxODY3IDEzLjIyMzNMMi4wMDIzMyAxMy44MzE3TDIuNjEwMzMgMTEuNTE1WiIgZmlsbD0iIzFDNERBMSIvPgo8L3N2Zz4K");
        width: 20px;
        height: 20px;
        display: inline-block;
        //background-image: url(data:image/svg;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDE1LjgzMzNIMFYxNi41SDE2VjE1LjgzMzNaIiBmaWxsPSIjMUM0REExIi8+CjxwYXRoIGQ9Ik0xLjk5OCAxNC41QzIuMDQxNjcgMTQuNSAyLjA4NjMzIDE0LjQ5NTcgMi4xMzEzMyAxNC40ODY3TDQuNDY5IDEzLjg3M0M0LjU5OCAxMy44NDczIDQuNzE2NjcgMTMuNzgzNyA0LjgwOTY3IDEzLjY5MDdMMTUuMTM4IDMuMzYyQzE1LjM5ODMgMy4xMDE2NyAxNS4zOTgzIDIuNjc5NjcgMTUuMTM4IDIuNDE5MzNMMTMuNDE0IDAuNjk1MzMzQzEzLjI4NCAwLjU2NSAxMy4xMTMzIDAuNSAxMi45NDI3IDAuNUMxMi43NzIgMC41IDEyLjYwMTMgMC41NjUgMTIuNDcxMyAwLjY5NTMzM0wyLjE0MjY3IDExLjAyNEMyLjA0OTY3IDExLjExNyAxLjk4NiAxMS4yMzU3IDEuOTYwMzMgMTEuMzY0N0wxLjM0NjY3IDEzLjcwMjNDMS4yNjIzMyAxNC4xMjM3IDEuNTkgMTQuNSAxLjk5OCAxNC41Wk0yLjYxMDMzIDExLjUxNUwyLjYxNDMzIDExLjQ5NTdMMTIuOTQyMyAxLjE2NjY3SDEyLjk0M0wxNC42NjY3IDIuODkwNjdMNC4zMzggMTMuMjE5M0w0LjMxODY3IDEzLjIyMzNMMi4wMDIzMyAxMy44MzE3TDIuNjEwMzMgMTEuNTE1WiIgZmlsbD0iIzFDNERBMSIvPgo8L3N2Zz4K);
      }
    }

    button {
      background: #000000;
      color: white;
      border-radius: 4px;
      padding: 9px 24px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }

    &__closeIcon {
      width: fit-content;
      display: block;
      content: "";
      background: image-set(url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x);
      background: -webkit-image-set(url(#{$globalImageUrl}xclose.png) 1x,
        url(#{$globalImageUrl}xclose@2x.png) 2x,
        url(#{$globalImageUrl}xclose@3x.png) 3x);
      background-repeat: no-repeat;
      color: transparent;
      min-width: 24px;
      height: 24px;
      background-size: 100%;
      align-self: center;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
    }

    table,
    th,
    td {
      font-size: 14px;
      border: 1px solid;
      border-collapse: collapse;
    }
  }

  &__Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.5;
  }

  .ManualTable {
    margin-top: 24px;

    @include rxc-mobile-only {
      font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.3px) * 15.5556))),
          16px);
      min-height: 0vw;
      padding: 0;
    }

    .marginTop {
      margin-top: 16px;
      word-break: break-word;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      &.desktop_table {
        @include rxc-small-mobile-only {
          display: none;
        }
      }

      &.mobile_table {
        @include rxc-desktop-only {
          display: none;
        }

        @include rxc-mobile-only {
          display: none;
        }

        @include rxc-small-mobile-only {
          display: inline-table;
        }
      }
    }

    th,
    td {
      border: 1px solid #d8d8d8;
      padding: 10px;
      max-width: 50px;
    }

    th {
      background: #f1f2f2;
      font-weight: 700;
    }
  }
}