@import "../../mixins/global";

.ExitModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
    }

    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 472px;
      height: auto;
      padding: 40px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 999999999999999;

      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }

    &__title {
      letter-spacing: 0.16px;
      text-transform: uppercase;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      color: #222222;
    }

    &__subtitle {
      text-align: center;
      letter-spacing: 0.16px;
      color: #222222;
      font-size: 12px;
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      margin-top: 4px !important;
    }

    &__buttonsContainer {
      margin-top: 22px;
      width: 100%;
      height: 32px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        width: 100%;
      }

      &__buttonExit,
      &__buttonContinue {
        letter-spacing: 0.5px;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        // line-height: 21px;
        // font-size: 16px;
        padding: 4px 16px;

        @include rxc-mobile-only {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          font-size: 12px;
          margin: 10px;
        }
      }

      &__buttonExit {
        background-color: #000;
        border: none;
        border-radius: 4px;
        width: 100%;
        min-height: 43px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 40px;

        @include rxc-mobile-only {
          font-weight: 500;
          min-height: 43px;
          max-width: 180px;
          text-align: center;
        }

        &:hover {
        }
      }

      &__buttonContinue {
        border: solid 1px #000;
        background-color: #fff;
        color: #000;
        border-radius: 4px;
        background-color: #fff;
        width: 100%;
        min-height: 43px;
        text-transform: uppercase;
        cursor: pointer;

        @include rxc-mobile-only {
          font-weight: 500;
          min-height: 43px;
          max-width: 180px;
          text-align: center;
        }

        &:hover {
        }
      }
    }

    &__save {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 26px;
      font-family: "Mulish";
      font-style: normal;
      line-height: 21px;
    }
  }
}
