@import "../../../mixins/global.scss";

.TreatmentStep {
  display: block;
}

.Treatmentsfamily {
  &__container {
    .confirmButton {
      border: none;
      cursor: pointer;
      text-transform: uppercase;

      span {
        //vertical-align: middle;
      }

      &__container {
        z-index: 1000;
        position: fixed;
        bottom: 83px;
        right: 40px;

        @include rxc-mobile-only {
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          display: grid;
          place-items: center;
          background-color: #fff;
          border-top: 1px solid #ced4d9;
        }
      }
    }

    padding-bottom: 100px;
  }
}
