@import "../../mixins/global";

.HowToReadModal {
  padding: 32px 40px;
  font-family: $rxc-clearly-font;

  @include rxc-mobile-only {
    max-width: 90dvw;
    max-height: 90dvh;
    overflow-y: auto;
    padding: 16px;
  }

  .PrescriptionModal__container__tabs {
    border: 1px solid $rxc-clearly-border-color;

    &.mobile {
      display: none;
      border: none;
    }

    @include rxc-mobile-only {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;

        .Tabs {
          margin-bottom: 24px;
          border: 1px solid $rxc-clearly-border-color;
        }
      }
    }
  }

  .PrescriptionModal__container__closeContainer {
    margin: 0;
  }

  .PrescriptionModal__container__title {
    font-family: $rxc-clearly-font !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0 0 16px 0;
    padding: 0;
    text-align: start;

    @include rxc-mobile-only {
      margin-bottom: 24px;
    }
  }

  .PrescriptionModal__container__subtitle {
    font-family: $rxc-clearly-font !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @include rxc-mobile-only {
      display: none;
    }
  }

  .table {
    padding: 24px;
  }

  @include rxc-mobile-only {
    .table {
      display: none;
    }
  }

  .infoText,
  .customerService {
    font-size: 14px;
    font-weight: 400;
    font-family: $rxc-clearly-font;
  }

  .infoText {
    margin: 16px 0px;
  }

  .customerService {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: $rxc-clearly-background-color;
    font-weight: 700;

    .icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M20.5 9.147V9C20.5 4.0375 16.687 0 12 0C7.313 0 3.5 4.0375 3.5 9V9.147C2.0545 9.5775 1 10.9145 1 12.5V14.5C1 16.433 2.567 18 4.5 18C6.433 18 8 16.433 8 14.5V12.5C8 10.9145 6.9455 9.5775 5.5 9.147V9C5.5 5.14 8.416 2 12 2C15.584 2 18.5 5.14 18.5 9V9.147C17.0545 9.5775 16 10.915 16 12.5V14.5C16 16.0855 17.0545 17.4225 18.5 17.853V18.041C18.5 19.6725 17.1725 21 15.541 21H13.7225C13.376 20.4045 12.7385 20 12 20C10.8955 20 10 20.8955 10 22C10 23.1045 10.8955 24 12 24C12.7385 24 13.376 23.5955 13.7225 23H15.541C18.2755 23 20.5 20.7755 20.5 18.041V17.853C21.9455 17.4225 23 16.085 23 14.5V12.5C23 10.9145 21.9455 9.5775 20.5 9.147ZM6 12.5V14.5C6 15.327 5.327 16 4.5 16C3.673 16 3 15.327 3 14.5V12.5C3 11.673 3.673 11 4.5 11C5.327 11 6 11.673 6 12.5ZM21 14.5C21 15.327 20.327 16 19.5 16C18.673 16 18 15.327 18 14.5V12.5C18 11.673 18.673 11 19.5 11C20.327 11 21 11.673 21 12.5V14.5Z' fill='black'/%3E%3C/svg%3E");

      @include rxc-mobile-only {
        width: 50px;
      }
    }
  }
}