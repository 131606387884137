@import "../../mixins/global";

.form {
  margin-top: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &_table {
    font: 14px $rxc-clearly-font, Verdana, Arial, sans-serif;
    display: flex;
    flex-flow: column wrap;

    &>div {
      border: 1px solid $rxc-clearly-border-color;

      @include rxc-mobile-only {
        flex-flow: column;
      }

      >div {
        margin: 0;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, 40px);
        place-items: center;

        &:not(:first-child) {
          border-left: 1px solid $rxc-clearly-border-color;
        }

        @include rxc-mobile-only {
          grid-auto-flow: column;
          grid-template-columns: repeat(3, minmax(80px, 120px));
          grid-template-rows: auto;

          &:not(:first-child) {
            border-top: 1px solid $rxc-clearly-border-color;
            border-left: none;
          }
        }

        >div {
          margin: 0 !important;
          padding: 8px;
          width: 100%;
          height: 40px;
          display: grid;
          place-content: center;

          &:not(:last-child) {
            border-bottom: 1px solid $rxc-clearly-border-color;
          }

          @include rxc-mobile-only {
            &:not(:last-child) {
              border-right: 1px solid $rxc-clearly-border-color;
              border-bottom: none;
            }
          }


          #left_eye_axis,
          #right_eye_axis {
            margin: 0 !important;
            border: none;
            width: 100% !important;
          }
        }
      }


    }

    .form_row:has(.rxc-prescription-select-error),
    .form_row:has(.form_select_prescription__error) {
      border: 1px solid $rxc-red-color-1;
    }

    &.readOnly {
      .form_row {
        pointer-events: none;
        cursor: none;

        >div {
          >div {
            padding: 0;
          }
        }
      }

      select,
      input {
        background-image: none !important;
        text-align: center;
      }

      #left_eye_axis,
      #right_eye_axis {
        background-image: none !important;
      }

      *::after {
        display: none;
      }
    }
  }

  &_leftcol {
    &::before {
      content: "OD (Right)";
      color: transparent;
      padding: 0px 8px;
      text-align: center;
      width: moz-fill-available;
      width: -webkit-fill-available;
      background: $rxc-clearly-background-color;
      border-bottom: 1px solid $rxc-clearly-border-color;
      height: 39px;

      @include rxc-mobile-only {
        border-right: 1px solid $rxc-clearly-border-color;
        border-bottom: none;
      }
    }

    background: $rxc-clearly-background-color;

    @include rxc-desktop-only {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 24px;
      margin-right: 23px;
      margin-bottom: 0;
      margin-left: 0px;
    }
  }

  &_leftlabel {
    font: 14px $rxc-clearly-font, Verdana, Arial, sans-serif;
    margin-bottom: 15px;
    background: $rxc-clearly-background-color;
    //border-top: 1px solid $rxc-glasses-border-color;
    display: grid;
    place-content: center;
  }

  &_col {
    &.disabled--text {
      color: #B0B0B0;
    }

    &.disableOS {
      >div:last-child>div {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &.highlight {
      &>div:not(:first-child) {
        border: 1px solid black;
      }

      &>div:nth-child(2) {
        border-bottom: 1px solid $rxc-clearly-border-color;
      }

      &>div:nth-child(3) {
        border-top: 1px solid $rxc-clearly-border-color;
      }
    }

    input {
      max-width: 100px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }


    input[type="number"] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      cursor: pointer;


      &:disabled {
        opacity: 0.5;
      }

      &:hover:not(:disabled),
      &:focus {
        background-image: none !important;
      }

      >* {
        width: 100%;
      }
    }

    &>div:first-child {
      background: $rxc-clearly-background-color;
    }

    &:not(:first-child)>div:not(:first-child) {
      background-color: #fff;
    }

    @include rxc-desktop-only {
      flex-flow: column nowrap;
      display: flex;
      justify-content: center;
      margin-right: 16px;
      margin-bottom: 15px;
    }

    &.invisible {
      display: none !important;
    }
  }

  &_row {

    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;

    .rxc-prescription-select {
      padding: 0 0 0 24px;
    }
  }

  &_boldsr {
    margin-right: 5px;
    font: 14px $rxc-clearly-font, Verdana, Arial, sans-serif;
  }

  &_select {
    direction: ltr;
    text-align-last: left;

    .arrows {
      display: flex;
      flex-direction: column;
      margin-right: 2px;
      gap: 4px;

      .add,
      .subtract {
        width: 12px;
        height: 12px;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }


      .add {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M11.1465 8.85348L5.99998 3.70722L0.853484 8.85348L0.146484 8.14647L5.99998 2.29272L11.8535 8.14647L11.1465 8.85348Z" fill="%23222222" /></svg>');
        background-repeat: no-repeat;
        background-size: contain;
      }

      .subtract {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M5.99998 9.70723L0.146484 3.85348L0.853484 3.14648L5.99998 8.29273L11.1465 3.14648L11.8535 3.85348L5.99998 9.70723Z" fill="%23222222"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_custom {
      border-radius: 6px;
      width: 100%;
    }

    & select {
      width: 98px;
      display: flex;
      justify-content: start;
      padding-left: 10px;
      padding-bottom: 2px;
      height: 28px;
      border: none;
      font-size: 14px !important;
      background-color: #ffffff;
      font-family: $rxc-clearly-font;
      color: #333333;
      border-radius: 8px;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
      position: relative;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC44NjIyNTQgMC41Mjg1MTRDMC42MDE5MDUgMC43ODg4NjQgMC42MDE5MDUgMS4yMTA5NyAwLjg2MjI1NCAxLjQ3MTMyTDUuNTI4OTIgNi4xMzc5OUM1Ljc4OTI3IDYuMzk4MzQgNi4yMTEzOCA2LjM5ODM0IDYuNDcxNzMgNi4xMzc5OUwxMS4xMzg0IDEuNDcxMzJDMTEuMzk4NyAxLjIxMDk3IDExLjM5ODcgMC43ODg4NjQgMTEuMTM4NCAwLjUyODUxNUMxMC44NzggMC4yNjgxNjUgMTAuNDU1OSAwLjI2ODE2NSAxMC4xOTU2IDAuNTI4NTE1TDYuMDAwMzMgNC43MjM3OEwxLjgwNTA2IDAuNTI4NTE0QzEuNTQ0NzEgMC4yNjgxNjUgMS4xMjI2IDAuMjY4MTY1IDAuODYyMjU0IDAuNTI4NTE0WiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K) !important;
      background: no-repeat;
      background-position: bottom 5px right 0px;
      background-size: 16px 16px;

      & input {
        font-size: 12px;
        line-height: 16px;
        border: none;
        outline: none;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #d8d8d8;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #767676;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #767676;
      }
    }

    &_prescription {
      border-radius: 4px;
      display: flex;
      align-items: center;

      &__disabled {
        display: flex;
        align-items: center;
      }

      &__add {
        width: 100px;
        height: 30px;
        border: none;
      }

      &__error {
        //border: 1px solid #e32118 !important;
        border-radius: 4px;
        display: flex;
        align-items: center;

        @include rxc-small-mobile-only {
          width: 100%;
        }

        & input {
          border: solid 1px transparent;

          &:focus {
            outline: none;
          }
        }
      }

      & select {
        width: 100%;
        height: 100%;
        margin: 0;
        padding-left: 20px;
        display: flex;
        justify-content: start;
        background-color: #ffffff;
        border: solid 1px transparent;
        color: #333333;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #767676;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #767676;
        }

        & input {
          font-size: 12px;
          line-height: 16px;

          &:focus {
            outline: none;
          }
        }

        @include rxc-small-mobile-only {
          width: 100%;
        }

        // @include rxc-medium-smaller-mobile-only {
        //   width: 98px;
        // }
      }
    }

    @include rxc-desktop-only {
      & #right_eye_axis {
        width: 100px;
        height: 30px;
        margin-top: 5px;
        border-radius: 6px;
        padding-left: 24px;
        padding-right: 0;
      }

      & #right_eye_axis:disabled {
        width: 100px;
        height: 30px;
        margin-top: 5px;
        border-radius: 6px;
        //padding-left: 24px;
        background-color: #ffffff;
        padding-right: 0;
      }

      & #left_eye_axis {
        width: 100px;
        height: 30px;
        margin-top: 5px;
        border-radius: 6px;
        padding-left: 24px;
        padding-right: 0;
      }

      & #left_eye_axis:disabled {
        width: 100px;
        height: 30px;
        margin-top: 5px;
        border-radius: 6px;
        //padding-left: 24px;
        background-color: #ffffff;
        padding-right: 0;
      }
    }

    @include rxc-mobile-only {
      & #right_eye_axis {
        width: 100%;
        height: 30px;
        border-radius: 6px;
        padding-left: 24px;
        padding-right: 0;
      }

      & #right_eye_axis:disabled {
        width: 100%;
        height: 30px;
        border-radius: 6px;
        padding-left: 24px;
        background-color: #ffffff;
        padding-right: 0;
      }

      & #left_eye_axis {
        width: 100%;
        height: 30px;
        border-radius: 6px;
        padding-left: 24px;
        padding-right: 0;
      }

      & #left_eye_axis:disabled {
        width: 100%;
        height: 30px;
        border-radius: 6px;
        padding-left: 24px;
        padding-right: 0;
        background-color: #ffffff;
      }
    }
  }
}