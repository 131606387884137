@import "../../mixins/global";
.Price {
  &__container {
    display: flex;
    text-align: left;
    align-items: baseline;
    padding-top: 2px;

    &__inner {
      font: 12px $rxc-sun-default-sofia-font;
      margin-bottom: 4px;

      &__strikePrice {
        margin-right: 6px;
        margin-left: 2px;
        color: #222222;
        font-size: 13px;
        text-decoration: line-through;
      }
      &__price {
        font: $rxc-sun-default-sofiaBold-font;
        color: #222222;
      }
      &__green {
        color: #1c8846 !important;
      }
    }
  }
}
