@import "../../mixins/global";

.InsuranceErrorPopup {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
    }
    &__popup {
      border-radius: 8px;
      box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
        0 0 2.9px 0 rgba(40, 41, 61, 0.04);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 472px;
      height: fit-content;
      padding: 40px 24px;
      text-align: center;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;
      @include rxc-mobile-only {
        width: 85%;
        padding: 30px 22px 26px 22px;
      }
    }
    &__title {
      color: #1f1f24;
      margin-bottom: 20px;
      line-height: 1.33;
      letter-spacing: 0.25px;
      font: 28px "minerva-modern", Verdana, Arial, sans-serif;
      @include rxc-mobile-only {
        font-size: 14px;
      }
    }
    &__subtitle {
      text-align: center;
      line-height: 1.33;
      letter-spacing: 1.25px;
      color: #222222;
      font: 14px "sofia-pro", Verdana, Arial, sans-serif;
      margin-bottom: 18px;
      @include rxc-mobile-only {
        font-size: 12px;
      }
    }
    &__button {
      font: 16px "sofia-pro", Verdana, Arial, sans-serif;
      background-color: #557b92;
      border: none;
      border-radius: 100px;
      width: 40%;
      padding: 12px 0 10px 0;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      @include rxc-mobile-only {
        font-size: 12px;
        width: 100%;
      }
      &:hover {
        background-color: $rxc-blue-color-2 !important;
        box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
          0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
      }
    }
  }
}
