@import "../../mixins/global";

.videoFrameButtonPlay.invisible {
  visibility: hidden;
}

.videoFrameButtonPause.invisible {
  visibility: hidden;
}

.VideoFrame {
  &__container {
    background: transparent;

    &.invisible {
      display: none;
    }
  }

  &__controls {
    display: flex;
    height: 64px;
    align-items: center;
    position: absolute;
    bottom: 50px;
    width: calc(100% - 50px - 50px);
    right: 50px;
    left: 50px;
  }

  &__buttonExit {
    position: absolute;
    top: 26px;
    right: 76px;
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z' fill='white' stroke='white' stroke-width='0.75'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;

    @include rxc-mobile-only {
      right: 15px;
      top: 15px;
    }
  }

  &__buttonPlay {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' width='24px'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z' fill='%23ffffff'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    cursor: pointer;
    margin-left: 24px;

    &.invisible {
      display: none;
    }
  }

  &__buttonPause {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' width='24'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z' fill='%23ffffff'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    cursor: pointer;
    margin-left: 24px;

    &.invisible {
      display: none;
    }
  }

  &__progressBar {
    width: 100%;
    height: 4px;
    border-radius: 100px;
    margin: 0 24px 0 8px;
    background-color: rgba(255, 255, 255, 0.2);

    &__elapsed {
      height: 4px;
      width: 0%;
      background: #fff;
      border-radius: 100px;
    }
  }

  &__buttonMade {
    position: absolute;
    top: 26px;
    left: 76px;
    height: 24px;
    line-height: 24px;
    color: #222222;
    border-radius: 12px;
    outline: none;
    font-size: 12px;
    border: none;
    font-family: "Sofia-Pro";
    font-weight: 600;
    font-size: 14px;
    padding: 0px 20px 3px 20px;
    background-color: white;
    cursor: pointer;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    user-select: none;

    &:hover {
      outline: none;
      text-decoration: underline;
    }
  }
}
